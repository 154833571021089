import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Radio } from '../../components/Radio';
import { RadioGroup } from '../../components/RadioGroup';
import { Select } from '../../components/Select';
import { TextArea } from '../../components/TextArea';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import { api } from '../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { PaymentMethod, Room, RoomOption, User } from '../../types';
//@ts-ignore
import BootPay from 'bootpay-js';
import { RoomOptionItem } from '../../components/RoomOptionItem';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

interface FormValues {
  checkIn: string;
  checkOut: string;
  adult: number;
  child?: number;
  infant?: number;
  requestedTerm?: string;
  minorsNotAllowedTerm: boolean;
  noSmoking: boolean;
  compensationRegulations: boolean;
  inflowOfInsects: boolean;
  unCookable: boolean;
  serviceUseTerms: boolean;
  informationCollectionTerms: boolean;
  refundRules: boolean;
  marketingTerms?: boolean;
  usedPoint?: number;
  refundBank?: string;
  depositor?: string;
  refundAccount?: string;
  reservationName: string;
  reservationPhoneNumber: string;
  reservationEmail: string;
  paymentMethod: PaymentMethod;
  roomId: number;
  orderId: string;
  receiptId: string;
  items?: {
    id: number;
    count: number;
  }[];
}

export const OrderPage = () => {
  const { id } = useParams<{ id: string }>();
  const { push } = useHistory();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.CARD
  );
  const [isAllUsed, setIsAllUsed] = useState<boolean>(false);
  const checkIn = localStorage.getItem('checkIn');
  const checkOut = localStorage.getItem('checkOut');

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: me } = useQuery<User>(`/users/me`, fetcher);
  const { data: room } = useQuery<Room>(
    `/rooms/selectDate/${id}?checkIn=${moment(
      checkIn
    ).toISOString()}&checkOut=${moment(checkOut).toISOString()}`,
    fetcher,
    {
      enabled: !!checkIn && !!checkOut && !!id,
    }
  );

  const { data: roomOptions } = useQuery<RoomOption[]>(
    `/room-options/room/${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  // 해당 날짜의 객실 총 금액 가져오는 api
  const { data: roomTotalPrice } = useQuery<number>(
    `/rooms/totalPrice/${id}?checkIn=${moment(
      checkIn
    ).toISOString()}&checkOut=${moment(checkOut).toISOString()}`,
    fetcher,
    {
      enabled: !!checkIn && !!checkOut && !!id,
    }
  );

  useEffect(() => {
    if (!me) return;
    setValue('reservationName', me.name);
    setValue('reservationPhoneNumber', me.phoneNumber);
    setValue('reservationEmail', me.email);
  }, [setValue, me]);

  useEffect(() => {
    if (!room) return;
    setValue('adult', room?.roomType?.id === 1 ? 1 : 2);
  }, [setValue, room]);

  // 선택한 옵션 상품
  const [items, setItems] = useState(
    roomOptions?.map((roomOption) => ({ id: roomOption.id, count: 0 }))
  );

  // 옵션 상품 금액
  const roomOptionPrice = roomOptions?.map((menu) => {
    const lists = items?.filter((item: any) => item.id === menu.id);
    const count = lists?.map((menu: any) => menu.count);
    return Number(count) * menu.price;
  });

  // 선택한 옵션 총 금액
  const roomOptionTotalPrice = roomOptionPrice?.reduce((stack, el) => {
    return stack + el;
  }, 0);

  // 인원 선택에 따른 추가 금액
  const roomPeopleCountPrice = room?.roomType
    ? room?.roomType?.id === 1
      ? watch('adult') === 2 &&
        (watch('child') === 0 ||
          !watch('child') ||
          watch('infant') === 0 ||
          !watch('infant'))
        ? room?.roomType?.adultAdditionalPrice
        : watch('adult') === 1 &&
          ((watch('child') !== 0 && !!watch('child')) ||
            (watch('infant') !== 0 && !!watch('infant')))
        ? room?.roomType?.childrenAdditionalPrice * (watch('child') || 0) +
          room?.roomType?.infantAdditionalPrice * (watch('infant') || 0)
        : 0
      : watch('adult') === 3 && (watch('child') !== 0 || watch('infant') !== 0)
      ? room?.roomType?.adultAdditionalPrice * (watch('adult') - 2) +
        room?.roomType?.childrenAdditionalPrice * (watch('child') || 0) +
        room?.roomType?.infantAdditionalPrice * (watch('infant') || 0)
      : watch('adult') > 2 &&
        (watch('child') === 0 ||
          !watch('child') ||
          !watch('infant') ||
          watch('infant') === 0)
      ? room?.roomType?.adultAdditionalPrice * (watch('adult') - 2)
      : watch('adult') === 2 && (watch('child') !== 0 || watch('infant') !== 0)
      ? room?.roomType?.childrenAdditionalPrice * (watch('child') || 0) +
        room?.roomType?.infantAdditionalPrice * (watch('infant') || 0)
      : 0
    : 0;

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    checkIn && checkOut && moment(checkOut).diff(moment(checkIn), 'days');

  // 연박 시 연박 할인 후 금액
  const discountPrice =
    countingNights &&
    room &&
    roomTotalPrice &&
    countingNights >= room?.roomType?.consecutiveNights
      ? roomTotalPrice - roomTotalPrice * (room?.roomType?.discountRate / 100)
      : roomTotalPrice;

  // 추가 인원이 있으면서 연박인 경우등 추가 인원에 관련된 금액 처리
  const roomAddPeopleCountPrice =
    roomPeopleCountPrice && countingNights && countingNights > 1
      ? roomPeopleCountPrice * countingNights
      : roomPeopleCountPrice;

  const finallyTotalPrice =
    (discountPrice || 0) +
    (roomOptionTotalPrice || 0) +
    (roomAddPeopleCountPrice || 0);

  // 포인트 사용 가능 금액
  const pointAvailableAmount =
    me && me?.totalPoint >= finallyTotalPrice
      ? finallyTotalPrice
      : me?.totalPoint;

  //예약 버튼 활성화 조건
  const disabledReservationButton =
    paymentMethod === PaymentMethod.BANK
      ? !watch('refundBank') ||
        !watch('depositor') ||
        !watch('refundAccount') ||
        !watch('adult') ||
        !watch('minorsNotAllowedTerm') ||
        !watch('noSmoking') ||
        !watch('compensationRegulations') ||
        !watch('inflowOfInsects') ||
        !watch('unCookable') ||
        !watch('serviceUseTerms') ||
        !watch('informationCollectionTerms') ||
        !watch('refundRules') ||
        !watch('reservationName') ||
        !watch('reservationPhoneNumber') ||
        !watch('reservationEmail')
      : !watch('adult') ||
        !watch('minorsNotAllowedTerm') ||
        !watch('noSmoking') ||
        !watch('compensationRegulations') ||
        !watch('inflowOfInsects') ||
        !watch('unCookable') ||
        !watch('serviceUseTerms') ||
        !watch('informationCollectionTerms') ||
        !watch('refundRules') ||
        !watch('reservationName') ||
        !watch('reservationPhoneNumber') ||
        !watch('reservationEmail');

  // 결제하기 버튼
  const paymentStart = handleSubmit(async (paymentOrder) => {
    if (!me) return;
    paymentOrder.paymentMethod = paymentMethod;
    paymentOrder.checkIn = moment(checkIn).toISOString();
    paymentOrder.checkOut = moment(checkOut).toISOString();
    paymentOrder.roomId = Number(id);
    paymentOrder.usedPoint = isAllUsed
      ? pointAvailableAmount || 0
      : watch('usedPoint') || 0;
    paymentOrder.orderId = me?.id + '_' + new Date().getTime();
    paymentOrder.items = !items ? [] : items?.filter((item) => item.count);

    if (paymentOrder.paymentMethod !== PaymentMethod.BANK) {
      try {
        const { data: order } = await api.post(
          '/payment-orders/start',
          paymentOrder
        );

        BootPay.request({
          price:
            finallyTotalPrice -
            (isAllUsed ? pointAvailableAmount || 0 : watch('usedPoint') || 0),
          application_id: process.env.REACT_APP_BOOTPAY_APPLICATION_ID,
          name: `아틴마루 ${room?.roomType.title} - ${room?.title} 예약 결제`,
          pg: 'nicepay',
          method:
            paymentOrder.paymentMethod === PaymentMethod.CARD
              ? 'card'
              : paymentOrder.paymentMethod === PaymentMethod.K_PAY
              ? 'kakao'
              : paymentOrder.paymentMethod === PaymentMethod.N_PAY
              ? 'npay'
              : '',
          show_agree_window: 0,
          user_info: {
            username: me?.name,
            email: me?.email,
            phone: me?.phoneNumber,
          },
          order_id: order.orderId,
          params: { orderId: order.orderId },
          extra: {
            quota: '0,2,3',
            theme: 'custom',
            custom_background: '#240201',
            custom_font_color: '#ffffff',
          },
        })
          .error(async (data: any) => {
            await api.post(`/payment-orders/${order.orderId}/expire`);
            await toast.error(data.msg);
            push('/reservation');
          })
          .cancel(async (data: any) => {
            await api.post(`/payment-orders/${order.orderId}/expire`);
            toast.error(data.msg);
          })
          .close(async (data: any) => {
            toast.error(data.msg);
          })
          .done(async (data: any) => {
            paymentOrder.receiptId = data.receipt_id;
            await api
              .patch('/payment-orders/confirm', paymentOrder)
              .then(() => {
                localStorage.removeItem('checkIn');
                localStorage.removeItem('checkOut');
                push(`/order/success?item=${order.id}`);
              })
              .catch((err) => {
                toast.error(err.response.data.message);
              });
          });
      } catch (err: any) {
        toast.error(err.response.data.message);
      }
    } else {
      await api
        .post('/payment-orders/paymentMethod/bank', paymentOrder)
        .then((res) => {
          localStorage.removeItem('checkIn');
          localStorage.removeItem('checkOut');
          push(`/order/success?item=${res.data.reservations[0].id}`);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  });

  if (!me) {
    return <></>;
  }
  return (
    <>
      <TopBar text="BOOKING" backButton />
      <div className="p-6 space-y-4">
        <h2 className="text-2xl font-medium">{room?.roomType.title}</h2>
        <TextField label="Room type" value={room?.title} disabled />

        <div className="grid grid-cols-2 gap-5">
          <TextField
            label="체크인"
            value={utcToLocalFormat(checkIn || '', MomentFormat.YMD)}
            disabled
          />
          <TextField
            label="체크아웃"
            value={utcToLocalFormat(checkOut || '', MomentFormat.YMD)}
            disabled
          />
        </div>

        <div className="text-sm text-gray-600 bg-gray-50 -mx-6 p-6">
          <p className="font-semibold">연박할인</p>
          <p>
            {room?.roomType.consecutiveNights}일 이상 숙박 시,{' '}
            {room?.roomType.discountRate}% 할인
          </p>
        </div>

        <h3 className="text-lg font-medium pt-5">예약자 정보</h3>
        <TextField
          label="이름"
          helper={errors.reservationName?.message}
          {...register('reservationName', {
            required: '예약자 이름을 입력해주세요',
          })}
        />
        <TextField
          label="핸드폰 번호"
          helper={errors.reservationPhoneNumber?.message}
          {...register('reservationPhoneNumber', {
            required: '예약자 전화번호를 입력해주세요',
          })}
        />
        <TextField
          label="이메일"
          type="email"
          helper={errors.reservationEmail?.message}
          {...register('reservationEmail', {
            required: '예약자 이메일을 입력해주세요',
          })}
        />

        <h3 className="text-lg font-medium pt-5">
          {room?.roomType?.id === 1
            ? '인원 (기본 1인, 최대 2인)'
            : '인원 (기본 2인, 최대 4인)'}
        </h3>

        <div className="grid grid-cols-3 gap-5">
          <div className="place-self-center w-full">
            {room?.roomType?.id === 1 ? (
              <Select
                label="성인(최대 2인)"
                value={watch('adult')}
                onChange={(e) => {
                  if (
                    Number(e.target.value) === 2 &&
                    !watch('child') &&
                    !watch('infant')
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else if (
                    Number(e.target.value) === 2 &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  } else if (
                    (Number(e.target.value) === 1 ||
                      Number(e.target.value) === 2) &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  }
                }}
              >
                <option value={1}>1명</option>
                <option value={2}>2명</option>
              </Select>
            ) : (
              <Select
                label="성인(최대 4인)"
                value={watch('adult')}
                onChange={(e) => {
                  if (
                    Number(e.target.value) === 4 &&
                    !watch('child') &&
                    !watch('infant')
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else if (
                    Number(e.target.value) === 3 &&
                    watch('child') === 1 &&
                    watch('infant') === 1
                  ) {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  } else if (
                    Number(e.target.value) === 4 &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  } else if (
                    (Number(e.target.value) === 2 ||
                      Number(e.target.value) === 3) &&
                    (watch('child') === 1 || watch('infant') === 1)
                  ) {
                    setValue('adult', Number(e.target.value));
                  } else {
                    setValue('adult', Number(e.target.value));
                    setValue('child', Number(0));
                    setValue('infant', Number(0));
                  }
                }}
              >
                <option value={2}>2명</option>
                <option value={3}>3명</option>
                <option value={4}>4명</option>
              </Select>
            )}
          </div>

          <div className="place-self-center w-full">
            {room?.roomType?.id === 1 ? (
              <Select
                label="아동(24개월~7세)"
                value={watch('child')}
                onChange={(e) => setValue('child', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 1 && !watch('infant') ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 1 && watch('infant') === 1 ? (
                  ''
                ) : watch('adult') === 2 && !watch('infant') ? (
                  ''
                ) : watch('adult') === 2 && watch('infant') === 1 ? (
                  ''
                ) : (
                  ''
                )}
              </Select>
            ) : (
              <Select
                label="아동(24개월~7세)"
                value={watch('child')}
                onChange={(e) => setValue('child', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 2 && !watch('infant') ? (
                  <>
                    <option value={1}>1명</option>
                    <option value={2}>2명</option>
                  </>
                ) : watch('adult') === 2 && watch('infant') === 1 ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 3 && !watch('infant') ? (
                  <option value={1}>1명</option>
                ) : (
                  ''
                )}
              </Select>
            )}
          </div>

          <div className="place-self-center w-full">
            {room?.roomType?.id === 1 ? (
              <Select
                label="영아(24개월 미만)"
                className="place-self-center w-full"
                value={watch('infant')}
                onChange={(e) => setValue('infant', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 1 && !watch('child') ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 1 && watch('child') === 1 ? (
                  ''
                ) : watch('adult') === 2 && !watch('child') ? (
                  ''
                ) : watch('adult') === 2 && watch('child') === 1 ? (
                  ''
                ) : (
                  ''
                )}
              </Select>
            ) : (
              <Select
                label="영아(24개월 미만)"
                className="place-self-center w-full"
                value={watch('infant')}
                onChange={(e) => setValue('infant', Number(e.target.value))}
              >
                <option value={0} disabled hidden selected>
                  인원수
                </option>
                <option value={0}>0명</option>
                {watch('adult') === 2 && !watch('child') ? (
                  <>
                    <option value={1}>1명</option>
                    <option value={2}>2명</option>
                  </>
                ) : watch('adult') === 2 && watch('child') === 1 ? (
                  <option value={1}>1명</option>
                ) : watch('adult') === 3 && !watch('child') ? (
                  <option value={1}>1명</option>
                ) : (
                  ''
                )}
              </Select>
            )}
          </div>
        </div>

        <div className="text-sm text-gray-600 bg-gray-50 -mx-6 p-6">
          <FroalaEditorView model={room?.reservationRoomInfo} />
        </div>

        <TextArea
          label="요청사항"
          placeholder="요청사항을 입력해주세요."
          className="h-36"
          {...register('requestedTerm')}
        />

        <div className="h-3 bg-gray-50 -mx-6 px-6" />
        <h3 className="text-lg font-medium">옵션사항</h3>
        <div className="space-y-14">
          {roomOptions?.map((roomOption, index) => (
            <RoomOptionItem
              key={roomOption.id}
              roomOption={roomOption}
              onChange={(value) =>
                setItems((prev) => {
                  const items = [...(prev || [])];
                  items[index] = value;
                  return items;
                })
              }
            />
          ))}
        </div>

        <div className="h-3 bg-gray-50 -mx-6 px-6" />
        <h3 className="text-lg font-medium">동의사항</h3>
        <Checkbox
          label="전체동의"
          onChange={() => {
            const allChecked =
              watch('minorsNotAllowedTerm') &&
              watch('noSmoking') &&
              watch('compensationRegulations') &&
              watch('inflowOfInsects') &&
              watch('unCookable');
            setValue('minorsNotAllowedTerm', !allChecked);
            setValue('noSmoking', !allChecked);
            setValue('compensationRegulations', !allChecked);
            setValue('inflowOfInsects', !allChecked);
            setValue('unCookable', !allChecked);
          }}
          checked={
            watch('minorsNotAllowedTerm') &&
            watch('noSmoking') &&
            watch('compensationRegulations') &&
            watch('inflowOfInsects') &&
            watch('unCookable')
          }
        />
        <div className="text-sm space-y-1 text-gray-600">
          <Checkbox
            label="미성년자 단독 체험 불가 (필수)"
            {...register('minorsNotAllowedTerm')}
            checked={watch('minorsNotAllowedTerm')}
          />
          <p>보호자 없는 미성년자는 예약 및 입실이 불가합니다.</p>
        </div>
        <div className="text-sm space-y-1 text-gray-600">
          <Checkbox
            label="금연 안내 (필수)"
            {...register('noSmoking')}
            checked={watch('noSmoking')}
          />
          <p>
            {room?.roomType?.id === 1 ? '야영장 내' : '객실 내, 외부'} 모든
            구역에서는 전자 담배를 포함하여 절대 금연입니다. 위반 시 즉각 퇴실
            조치와 추가 청소비를 청구합니다. 지정된 흡연 장소를 이용해주세요.
          </p>
        </div>
        <div className="text-sm space-y-1 text-gray-600">
          <Checkbox
            label="변상 규정 (필수)"
            {...register('compensationRegulations')}
            checked={watch('compensationRegulations')}
          />
          <p>
            {room?.roomType?.id === 1 ? '캐빈' : '객실'} 내 기물 파손 및 침구
            오염 등이 생길 경우 호스트에게 반드시 알려주셔야 하며 퇴실점검 이후
            협의 되지 않은 확인된 파손, 오염, 분실에 대한 배상비용을 청구합니다.
          </p>
        </div>
        <div className="text-sm space-y-1 text-gray-600">
          <Checkbox
            label="벌레 유입 안내 (필수)"
            {...register('inflowOfInsects')}
            checked={watch('inflowOfInsects')}
          />
          <p>
            우거진 산 속의 {room?.roomType?.id === 1 ? '캐빈' : '객실'} 내부로
            벌레나 야생 동물이 목격될 수 있으니 주의 바랍니다. 벌레로 인한
            환불은 불가능합니다. {room?.roomType?.id === 1 ? '캐빈' : '객실'}{' '}
            내에 전기 모기채가 비치되어 있습니다.
          </p>
        </div>
        <div className="text-sm space-y-1 text-gray-600">
          <Checkbox
            label="조리 제한 안내 (필수)"
            {...register('unCookable')}
            checked={watch('unCookable')}
          />
          <p>
            {room?.roomType?.id === 1 ? '캐빈' : '객실'} 내 냄새 및 연기가 많이
            발생되는 조리는 하실 수 없습니다. (실내 해산물 및 육류 조리 절대
            금지)
          </p>
        </div>

        <div className="h-3 bg-gray-50 -mx-6 px-6" />
        <h3 className="text-lg font-medium">적립금 사용</h3>
        <div className="grid grid-cols-3 gap-5">
          <div className="col-span-2">
            <TextField
              type="number"
              value={
                isAllUsed
                  ? (pointAvailableAmount || 0).toString().replace(/(^0+)/, '')
                  : watch('usedPoint')?.toString().replace(/(^0+)/, '')
              }
              max={pointAvailableAmount || 0}
              min="0"
              onChange={(e) => {
                if (Number(e.target.value) > (pointAvailableAmount || 0)) {
                  toast.error(
                    '보유 적립금 이하 및 결제금액 이하 적립금만 사용가능합니다.'
                  );
                } else {
                  setIsAllUsed(false);
                  setValue('usedPoint', Number(e.target.value));
                }
              }}
            />
            <span className="text-gray-500 text-sm mr-2">사용가능 적립금</span>
            <span className="font-medium">
              {(isAllUsed
                ? (me?.totalPoint || 0) - (pointAvailableAmount || 0)
                : (me?.totalPoint || 0) - (watch('usedPoint') || 0)
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              원
            </span>
          </div>
          <Button
            text="모두 사용"
            className="filled-brand-1 text-sm h-12"
            onClick={() => setIsAllUsed(true)}
          />
        </div>

        <h3 className="text-lg font-medium">결제방법</h3>
        <div className="grid grid-cols-3 gap-x-5 gap-y-2 w-full">
          <RadioGroup>
            <Radio
              label="신용카드"
              checked={paymentMethod === PaymentMethod.CARD}
              onClick={() => setPaymentMethod(PaymentMethod.CARD)}
            />
            <Radio
              label="네이버페이"
              checked={paymentMethod === PaymentMethod.N_PAY}
              onClick={() => setPaymentMethod(PaymentMethod.N_PAY)}
            />
            <Radio
              label="카카오페이"
              checked={paymentMethod === PaymentMethod.K_PAY}
              onClick={() => setPaymentMethod(PaymentMethod.K_PAY)}
            />
            <Radio
              label="무통장입금"
              checked={paymentMethod === PaymentMethod.BANK}
              onClick={() => setPaymentMethod(PaymentMethod.BANK)}
            />
          </RadioGroup>
        </div>

        {paymentMethod === PaymentMethod.BANK && (
          <>
            <div className="grid grid-cols-2 gap-2">
              <TextField
                label="환불시 환불받을 은행"
                placeholder="환불시 환불받을 은행"
                helper={errors.refundBank?.message}
                {...register('refundBank', {
                  required: '환불시 환불받을 은행을 입력해주세요',
                })}
              />
              <TextField
                label="예금주명"
                placeholder="예금주명"
                helper={errors.depositor?.message}
                {...register('depositor', {
                  required: '예금주명을 입력해주세요',
                })}
              />
              <div className="col-span-2">
                <TextField
                  label="환불 계좌"
                  helper={errors.refundAccount?.message}
                  {...register('refundAccount', {
                    required: '환불 계좌를 입력해주세요',
                  })}
                />
              </div>
            </div>
            <p className="text-12 text-brand-1">
              * 현재 결제시스템 문제로 인하여 무통장 입금 예약만 가능하오며 이후
              입실일에 요청시 카드결제로 변경 하실 수 있습니다.
            </p>
          </>
        )}

        <div className="h-3 bg-gray-50 -mx-6 px-6" />
        <h3 className="text-lg font-medium">이용자 약관 동의</h3>
        <div className="space-y-3">
          <Checkbox
            label="사용자 약관 전체 동의"
            onChange={() => {
              const allChecked =
                watch('serviceUseTerms') &&
                watch('informationCollectionTerms') &&
                watch('refundRules') &&
                watch('marketingTerms');

              setValue('serviceUseTerms', !allChecked);
              setValue('informationCollectionTerms', !allChecked);
              setValue('refundRules', !allChecked);
              setValue('marketingTerms', !allChecked);
            }}
            checked={
              watch('serviceUseTerms') &&
              watch('informationCollectionTerms') &&
              watch('refundRules') &&
              watch('marketingTerms')
            }
          />

          <div className="flex items-center space-x-1">
            <Checkbox
              label="(필수)"
              {...register('serviceUseTerms')}
              checked={watch('serviceUseTerms')}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/f7d49d7c21bd4460afa3a7f47502bf3d"
              target="_blank"
              className="text-sm text-gray-800 font-semibold"
              rel="noreferrer"
            >
              서비스 이용 약관동의
            </a>
          </div>

          <div className="flex items-center space-x-1">
            <Checkbox
              label="(필수)"
              {...register('informationCollectionTerms')}
              checked={watch('informationCollectionTerms')}
            />
            <a
              href="https://prairie-porcupine-6b9.notion.site/f61a5788a25a422da43dac0ab6156b2a"
              target="_blank"
              className="text-sm text-gray-800 font-semibold"
              rel="noreferrer"
            >
              개인정보 취급방침 동의
            </a>
          </div>

          <Checkbox
            label="(필수) 아틴 - 환불규정에 대한 동의 "
            {...register('refundRules')}
            checked={watch('refundRules')}
          />
          <Checkbox
            label="(선택) 마케팅 정보 수신 동의 "
            {...register('marketingTerms')}
            checked={watch('marketingTerms')}
          />
        </div>

        <div className="pt-10 space-y-1.5">
          {[
            {
              label: '객실 요금',
              content:
                (roomTotalPrice || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '추가옵션',
              content:
                (roomOptionTotalPrice || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '추가 인원 요금',
              content:
                (roomAddPeopleCountPrice || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
            {
              label: '적립금 사용 금액',
              content:
                (isAllUsed
                  ? pointAvailableAmount || 0
                  : watch('usedPoint') || 0
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
            },
            {
              label: '적립금 적립 예정',
              content:
                (
                  ((finallyTotalPrice -
                    (isAllUsed
                      ? pointAvailableAmount || 0
                      : watch('usedPoint') || 0)) /
                    100) *
                  5
                )
                  .toFixed()
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
            },
            {
              label: '연박 할인 금액',
              content:
                (roomTotalPrice &&
                room &&
                countingNights &&
                countingNights >= room?.roomType?.consecutiveNights
                  ? roomTotalPrice * (room?.roomType?.discountRate / 100)
                  : 0
                )
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
            },
          ].map((e) => (
            <div className="flex justify-between items-center text-sm">
              <div className="text-gray-600">{e.label}</div>
              <div className="font-medium text-gray-900">{e.content}</div>
            </div>
          ))}

          <div className="flex justify-between items-center text-lg font-semibold">
            <div className="">최종 결제 금액</div>
            <div className="">
              {(
                finallyTotalPrice -
                (isAllUsed
                  ? pointAvailableAmount || 0
                  : watch('usedPoint') || 0)
              )
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
            </div>
          </div>
          <div className="text-12 text-red-400 pt-3">
            * 결제 시작 후 3분 내에 결제가 완료되지 않으면 예약이 취소 될 수
            있습니다.
          </div>
        </div>
      </div>
      <div className="pt-16">
        <Button
          type="button"
          text="결제하기"
          className="filled-brand-1 h-16 fixed bottom-0 w-full max-w-md"
          disabled={disabledReservationButton || room?.timeslots.length === 0}
          onClick={paymentStart}
        />
      </div>
    </>
  );
};
