import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { TextField } from '../../../components/TextField';
import { adminApi } from '../../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { adminFetcher } from '../../../plugins/react-query';
import { User } from '../../../types';

interface FormValues {
  name: string;
  email: string;
  password: string;
  phoneNumber: string;
  marketingTerms: boolean;
}

export const UserInfo = () => {
  const { id } = useParams<{ id: string }>();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: user } = useQuery<User>(`/admin/users/${id}`, adminFetcher, {
    enabled: !!id,
    keepPreviousData: true,
  });

  useEffect(() => {
    if (!user) return;
    setValue('name', user.name);
    setValue('email', user.email);
    // setValue('password', user.password);
    setValue('phoneNumber', user.phoneNumber);
    setValue('marketingTerms', user.marketingTerms);
  }, [setValue, user]);

  if (!user) {
    return <></>;
  }
  return (
    <div className="p-6 space-y-5">
      <p className="text-sm text-gray-500">
        {utcToLocalFormat(user.createdAt, MomentFormat.YYYYMMDD)} 가입
      </p>
      <TextField
        label="이름"
        placeholder="이름을 입력해주세요."
        helper={errors.name?.message}
        {...register('name', {
          required: '이름을 입력해주세요',
        })}
      />
      <TextField
        label="이메일"
        placeholder="이메일을 입력해주세요."
        helper={errors.email?.message}
        {...register('email', {
          required: '이메일을 입력해주세요',
        })}
      />
      <TextField
        type="password"
        label="비밀번호"
        placeholder="영문 숫자 포함 8자리를 입력해주세요."
        // helper={errors.password?.message}
        {...register('password', {
          // required: '비밀번호를 입력해주세요',
          // minLength: { value: 8, message: '최소 8자 이상 가능합니다' },
          // maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
        })}
      />
      <TextField
        label="핸드폰 번호"
        type="number"
        placeholder="핸드폰 번호를 입력해주세요."
        helper={errors.phoneNumber?.message}
        {...register('phoneNumber', {
          required: '핸드폰 번호를 입력해주세요.',
        })}
      />
      <Checkbox label="이용약관에 동의(필수)" checked={user.termsOfService} />
      <Checkbox
        label="개인정보 취급방침 동의(필수)"
        checked={user.privacyPolicy}
      />
      <Checkbox label="제3자 동의(필수)" checked={user.thirdPartyConsent} />
      <Checkbox
        label="마케팅 약관 동의(선택)"
        {...register('marketingTerms')}
        checked={watch('marketingTerms')}
      />
      <Button
        text="저장하기"
        className="filled-brand-1 w-full"
        disabled={!watch('name') || !watch('email') || !watch('phoneNumber')}
        onClick={handleSubmit(async (data) => {
          try {
            await adminApi.patch(`admin/users/${id}`, data);
            toast.success('저장되었습니다.');
          } catch (e) {
            toast.error('유저 정보 수정 오류');
          }
        })}
      />
    </div>
  );
};
