import React, { FC, useState } from 'react';
import { Button } from '../../../components/Button';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { Reservation, ReservationState, RoomType } from '../../../types';
import { Search } from '../../components/Search';
import moment from 'moment';
import { useQuery } from 'react-query';
import { adminFetcher } from '../../../plugins/react-query';
import { stringify } from 'qs';
import { ReservationCard } from '../../components/ReservationCard';

interface ReservationCalendarProps {
  roomType: RoomType;
}

export const ReservationList: FC<ReservationCalendarProps> = ({ roomType }) => {
  const today = moment();
  const [reservationState, setReservationState] = useState<
    ReservationState | string
  >();
  const [selectDate, setSelectDate] = useState<string>(
    today.startOf('day').format('YYYY-MM-DD')
  );
  const [roomId, setRoomId] = useState<number>();
  const [q, setQ] = useState<string>('');

  const { data: reservations } = useQuery<Reservation[]>(
    `/admin/reservations/roomType/${roomType.id}/list?${stringify({
      selectedDate: moment(selectDate).toISOString(),
      reservationState: reservationState,
      roomId: roomId,
      q: q,
    })}`,
    adminFetcher,
    { enabled: !!roomType }
  );

  return (
    <div>
      <div className="space-y-3 px-6 pt-6">
        <div className="grid grid-cols-2 gap-x-3">
          <Select
            value={reservationState}
            onChange={(e) =>
              setReservationState(e.target.value as ReservationState)
            }
          >
            <option value={''} selected disabled>
              전체상태
            </option>
            <option value={ReservationState.BEFORE_DEPOSIT}>입금전</option>
            <option value={ReservationState.APPLICATION_FOR_RESERVATION}>
              예약신청
            </option>
            <option value={ReservationState.RESERVATION_CONFIRMATION}>
              예약확정
            </option>
            <option value={ReservationState.CANCELLATION_REQUEST}>
              취소신청
            </option>
            <option value={ReservationState.CANCELED}>취소완료</option>
            <option value={ReservationState.USED}>이용완료</option>
          </Select>
          <Select
            value={roomId}
            onChange={(e) => setRoomId(Number(e.target.value))}
          >
            <option selected disabled>
              전체 룸
            </option>
            {roomType.rooms.map((room) => (
              <option key={room.id} value={room.id}>
                {room.title}
              </option>
            ))}
          </Select>
        </div>
        <TextField
          type="date"
          value={selectDate}
          onChange={(e) => setSelectDate(e.target.value)}
        />
        <div className="grid grid-cols-4 gap-x-3">
          <div className="col-span-3">
            <Search
              placeholder="검색"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </div>
          <Button text="검색" className="filled-brand-1 text-sm h-full" />
        </div>
      </div>
      {!reservations || reservations.length === 0 ? (
        <p className="mt-5 pl-5">해당 날짜의 예약이 없습니다.</p>
      ) : (
        <>
          {reservations.map((reservation) => (
            <ReservationCard reservation={reservation} />
          ))}
        </>
      )}
    </div>
  );
};
