import React from 'react';
import { TopBar } from '../../components/TopBar';
import { CardType, MainCard } from '../../components/MainCard';
import lounge from '../../assets/img/lounge.png';
import cabins from '../../assets/img/cabins.png';
import environment from '../../assets/img/environment.png';
import { useLocation, useParams } from 'react-router-dom';

export const Gallery = () => {
  const { id } = useParams<{ id: string }>();
  const { state } = useLocation<any>();

  return (
    <>
      <TopBar text={state.title} />

      <div className="flex-1 relative flex flex-col">
        <MainCard
          text1="01"
          text2="Lounge"
          src={lounge}
          link={`/gallery/lounge/${id}`}
          cardType={CardType.FULL}
        />
        <MainCard
          text1="02"
          text2="Cabins"
          src={cabins}
          link={`/gallery/cabins/${id}`}
          cardType={CardType.FULL}
        />
        <MainCard
          text1="03"
          text2="Environment"
          src={environment}
          link={`/gallery/environment/${id}`}
          cardType={CardType.FULL}
        />
      </div>
    </>
  );
};
