import React, { useState } from 'react';
import { CardType, MainCard } from '../../components/MainCard';
import { DefaultModal } from '../../components/modal/DefaultModal';
import { TopBar } from '../../components/TopBar';

export const ShopMain = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <TopBar text="SHOP" backButton />
      <DefaultModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="준비중입니다."
        description="곧 오픈될 예정이니, 조금만 기다려주세요!"
      />
      <div className="flex-1 relative flex flex-col">
        <MainCard
          text1="01"
          text2="SHOP"
          bgColor="brown"
          onClick={() => setIsOpen(true)}
          cardType={CardType.FULL}
        />
        <MainCard
          text1="02"
          text2="VOUCHER"
          bgColor="lightGray"
          link="/shop/voucher"
          cardType={CardType.FULL}
        />
      </div>
    </>
  );
};
