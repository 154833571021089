import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import { api } from '../../plugins/axios';

interface FormValues {
  password: string;
  verifyPassword: string;
  resetToken: string;
}

export const FindPasswordResetPage = () => {
  const { push } = useHistory();
  const { state } = useLocation<any>();

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <TopBar text="새로운 비밀번호 입력" backButton />

      <div className="pl-6 py-8 text-brand-1 text-24 font-semibold">
        새로운 비밀번호를 입력해주세요.
      </div>

      <form className="flex flex-col space-y-4">
        <div className="p-6 flex flex-col space-y-4">
          <TextField
            type="password"
            label="비밀번호"
            placeholder="영문 숫자 포함 8자리를 입력해주세요."
            helper={errors.password?.message}
            {...register('password', {
              required: '비밀번호를 입력해주세요',
              minLength: { value: 8, message: '최소 8자 이상 가능합니다' },
              maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
            })}
          />

          <TextField
            type="password"
            label="비밀번호 재입력"
            placeholder="비밀번호를 재입력해주세요."
            helper={errors.verifyPassword?.message}
            {...register('verifyPassword', {
              required: '비밀번호 재입력해주세요',
              minLength: { value: 8, message: '최소 8자 이상 가능합니다' },
              maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
            })}
          />

          <div className="pt-8">
            <Button
              type="button"
              text="다음"
              className="filled-brand-1 w-full"
              disabled={!watch('password') || !watch('verifyPassword')}
              onClick={handleSubmit(async (data) => {
                data.resetToken = state.resetToken;
                if (watch('password') === watch('verifyPassword')) {
                  await api
                    .post('/users/reset/password', data)
                    .then((res) => {
                      if (res.data.id) {
                        toast.success('비밀번호 변경이 완료되었습니다.');
                        push('/login');
                      } else {
                        toast.error('비밀번호 변경 오류');
                      }
                    })
                    .catch((err) => toast.error(err.response.data.message));
                } else {
                  toast.error('비밀번호가 일치하지 않습니다.');
                }
              })}
            />
          </div>
        </div>
      </form>
    </>
  );
};
