import React, { ButtonHTMLAttributes, FC } from 'react';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected?: boolean;
  text: string;
  hasBorder?: boolean;
  className?: string;
}

export const Tab: FC<TabProps> = ({
  selected,
  text,
  hasBorder = false,
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`${className} py-3 ${
        selected
          ? `font-semibold text-gray-900 ${
              hasBorder && 'border-b-2 border-black'
            }`
          : 'text-gray-500'
      }`}
    >
      {text}
    </button>
  );
};
