import React from 'react';
import { Description } from '../../components/Description';
import { TopBar } from '../../components/TopBar';
import environment_1 from '../../assets/img/environment_1.png';
import environment_2 from '../../assets/img/environment_2.png';
import environment_3 from '../../assets/img/environment_3.png';
import environment_4 from '../../assets/img/environment_4.png';
import environment_5 from '../../assets/img/environment_5.png';
import environment_6 from '../../assets/img/environment_6.png';
import environment_7 from '../../assets/img/environment_7.png';

export const Environment = () => {
  return (
    <>
      <TopBar text="ENVIRONMENT" backButton />
      <div className="p-6">
        <img src={environment_1} alt="" className="w-full" />
        <Description
          className="pb-0"
          title="ENVIRONMENT"
          desc={`층숲을 체험해보세요.

          숲의 아침을 맞이하는 날, 안내되는 트래킹을 경험해보세요.
          
          외부에 마련된 테이블과 의자에서 머물러보세요.
          
          가져오신 천막, 테이블과 의자 등을 통해 이용객 모두가 사용 가능한 공간에서 캠프를 즐기세요.
          
          설치된 그네와 해먹은 안전하게 사용해주세요.
          
          gallery
          update. 2021. 10`}
        />
      </div>
      <img src={environment_2} alt="" className="w-full" />
      <img src={environment_3} alt="" className="mx-10 mt-16" />
      <img src={environment_4} alt="" className="mx-10 mt-16" />
      <img src={environment_5} alt="" className="mx-10 mt-16" />
      <img src={environment_6} alt="" className="mx-10 mt-16" />
      <img src={environment_7} alt="" className="mx-10 my-16" />
    </>
  );
};
