import { parse } from 'qs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { TopBar } from '../../../components/TopBar';
import { adminApi } from '../../../plugins/axios';

interface FormValues {
  title: string;
  description: string;
  price: number;
}

export const OptionAdd = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const { roomTypeId } = parse(search, { ignoreQueryPrefix: true });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <TopBar text="추가 옵션 추가" backButton />
      <div className="p-6 space-y-4 flex-1 flex flex-col">
        <h3 className="text-xl font-semibold">추가 옵션 추가</h3>
        <TextField
          label="옵션명"
          placeholder="옵션명을 입력해주세요."
          helper={errors.title?.message}
          {...register('title', {
            required: '옵션명을 입력해주세요.',
          })}
        />
        <TextField
          label="수량당 추가금액"
          type="number"
          placeholder="수량당 추가금액을 입력해주세요."
          helper={errors.price?.message}
          {...register('price', {
            required: '수량당 추가금액을 입력해주세요.',
          })}
        />
        <TextArea
          label="상세설명"
          className="h-44"
          placeholder="상세설명을 입력해주세요."
          helper={errors.description?.message}
          {...register('description', {
            required: '상세설명을 입력해주세요.',
          })}
        />
        <div className="flex-1" />
        <div className="space-y-3">
          <Button
            text="추가하기"
            className="filled-brand-1 w-full"
            disabled={
              !watch('title') || !watch('price') || !watch('description')
            }
            onClick={handleSubmit(async (data) => {
              const isOkay = window.confirm('정말 추가하시겠습니까?');
              try {
                if (isOkay) {
                  await adminApi.post(
                    `/admin/room-options/roomType/${Number(roomTypeId)}`,
                    data
                  );
                  toast.success('결제시 추가 옵션 설정이 생성되었습니다.');
                  push('/admin/rooms/option');
                }
              } catch (e) {
                console.log('추가 옵션 생성 오류', e);
                toast.error('추가 옵션 생성 오류');
              }
            })}
          />
        </div>
      </div>
    </>
  );
};
