import React from 'react';
import { Description } from '../../components/Description';
import { TopBar } from '../../components/TopBar';
import cabins_1 from '../../assets/img/cabins_1.png';
import cabins_2 from '../../assets/img/cabins_2.png';
import cabins_3 from '../../assets/img/cabins_3.png';
import cabins_4 from '../../assets/img/cabins_4.png';
import cabins_5 from '../../assets/img/cabins_5.png';
import cabins_6 from '../../assets/img/cabins_6.png';
import cabins_7 from '../../assets/img/cabins_7.png';
import cabins_8 from '../../assets/img/cabins_8.png';
import cabins_9 from '../../assets/img/cabins_9.png';

export const Cabins = () => {
  return (
    <>
      <TopBar text="CABINS" backButton />
      <img src={cabins_1} alt="" className="w-full" />
      <div className="p-6">
        <Description
          className="py-0"
          title="CABINS"
          desc={`캐빈 구성 [자연 체험장]
          Nature experience private cabin
          
          spring
          summer
          autumn
          winter
          
          면적, 구성 모두 동일, 위치만 상이
          캐빈별 위치적 특징`}
        />
        <Description
          className="pb-0"
          subTitle="Spring"
          desc={`본관과의 거리 56m, 잣나무 숲의 뷰 포인트`}
        />
        <Description
          className="pb-0"
          subTitle="Summer"
          desc={`본관과의 거리 72m, 개울가 인접, 벚나무`}
        />
        <Description
          className="pb-0"
          subTitle="Autumn"
          desc={`본관과의 거리 106m, 잣나무 숲 속에 위치`}
        />
        <Description
          className="pb-0"
          subTitle="Winter"
          desc={`본관과의 거리 142m, 호텔 전경 포인트`}
        />
        <Description
          className="pb-0 pt-12"
          subTitle="캐빈 간 거리"
          desc={`15m – 58m`}
        />
        <Description className="pb-4" subTitle="캐빈 면적" desc={`8평`} />
        <Description
          className="pb-0"
          subTitle="실내 구성"
          desc={`고정식 싱글 침대 2개, 고정식 창가 테이블, 원목 밴치, 화장실(건식), 샤워실, 보일러 및 비품실(Staff only)`}
        />
        <Description
          className="pb-0"
          subTitle="비품 및 자연 체험 도구"
          desc={`매트리스, 천연 목화솜 침구, 천연 광목천 침구 커버, Britz 블루투스 & CD 스피커, 전기포트, 냉장고, 헤어드라이기, 무선 선풍기, 무선 모기채, 타월, 천연 오일, 어메니티(천연 수제 비누, 바디, 샴푸 바), 거품망, 메모지, 볼펜, 물컵 2개, 천장형 냉방기, 바닥 온수 보일러`}
        />
        <Description
          className="pb-0"
          subTitle="주의 사항"
          desc={`캐빈 내에서는 3사 통신사 신호가 매우 약하며 Wifi는 설치되어 있지 않습니다.
          전 캐빈 내 절대 금연이며 지정 장소 외 흡연 시 별도 청소비 5만원이 추가됩니다. 
          캐빈 내에 취사 및 조리를 금합니다. 개인 화기 이용 시 강제 퇴장 조치됩니다.
          (전기 포트는 구비 되어 있습니다.)
          외출 시에는 캐빈 출입문 이외에 중문을 닫아주시길 바랍니다.
          (혹시 모를 벌레 유입을 막고 실내 온도를 유지할 수 있습니다.)
          체험 이용 시간을 엄수 해주세요. 차단기에서 출입 및 출차하는 기준입니다.
          (사전 협의 되지 않은 early check in & late check out 시 별도 요금이 청구됩니다.)
          포장 음식 섭취 후 재활용 및 음식물 쓰레기는 본관 라운지로 가져다 주셔야 합니다.
          (객실 내 처리 금지, 배수구 막힘 및 악취 방지)
          라운지에 비치 된 음악 CD를 대여해가실 수 있습니다. 퇴실 시 반납 부탁 드립니다.
          제공된 어메니티 중 노란색의 구 형태는 샴푸입니다. 제공된 거품 망을 이용해주세요.
          화장실 내의 변기에는 휴지 이외의 쓰레기를 버리지 마시고 비치 된 휴지통에 버려주시길 바랍니다.
          외부에 벌레가 많습니다. 출입부 현관에 무선 전기 모기 채가 비치 되어 있습니다.
          시설물 및 비품의 외부 유출을 금하며 파손이나 분실 시에는 전액 변상하셔야 함을 알려드립니다.
          
          gallery
          update. 2021. 10`}
        />
      </div>
      <img src={cabins_2} alt="" className="mx-5 mt-16" />
      <img src={cabins_3} alt="" className="mx-12 mt-16" />
      <img src={cabins_4} alt="" className="mx-12 my-16" />
      <img src={cabins_5} alt="" className="w-full mb-16" />
      <img src={cabins_6} alt="" className="mx-5" />
      <img src={cabins_7} alt="" className="w-full mt-16" />
      <img src={cabins_8} alt="" className="mx-5 mt-16" />
      <img src={cabins_9} alt="" className="mx-5 my-16" />
    </>
  );
};
