import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/image_manager.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/code_beautifier.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/help.min.js';
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';
import 'froala-editor/js/plugins/quick_insert.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { tokenState } from './ridge';
export const EditorRoom = (props: any) => {
  const token = tokenState.get();
  const config = Object.assign(
    {
      requestHeaders: {
        Authorization: `Bearer ${token}`,
      },
      imageUploadURL: `${process.env.REACT_APP_ADMIN_API_URL}/admin/descriptions/upload/room`,
      imageUploadMethod: 'POST',
      imageAllowedTypes: ['jpeg', 'jpg', 'png'],
      imageInsertButtons: ['imageUpload'],
      imageEditButtons: [
        'imageDisplay',
        'imageAlign',
        'imageRemove',
        'imageLink',
        'linkEdit',
      ],
      key: process.env.REACT_APP_FROALA_KEY,
    },
    props.config
  );

  return (
    <FroalaEditor
      config={config}
      model={props.model}
      onModelChange={props.onModelChange}
    />
  );
};

export { FroalaEditorView as EditorViewRoom };
