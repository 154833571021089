import React, { useState } from 'react';
import { TopBar } from '../../components/TopBar';
import marubooking from '../../assets/img/marubooking.png';
import haeubooking from '../../assets/img/haeubooking.png';
import { CardType, MainCard } from '../../components/MainCard';
import { useQuery } from 'react-query';
import { RoomType } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { usePopup } from '../../components/PopUp';
import { DefaultModal } from '../../components/modal/DefaultModal';

export const ReservationRoomTypePage = () => {
  const { push } = useHistory();
  const { authenticated } = useAuth();
  const { requireLoginPopup } = usePopup();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: roomTypes } = useQuery<RoomType[]>('/room-types/all', fetcher);

  return (
    <>
      <DefaultModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="준비중입니다."
        description="곧 오픈될 예정이니, 조금만 기다려주세요!"
      />
      <TopBar text="BOOKING" />
      <div className="flex-1 relative flex flex-col">
        {roomTypes?.map((roomType, i) => (
          <MainCard
            key={i}
            src={roomType.id === 1 ? marubooking : haeubooking}
            onClick={() => {
              if (authenticated) {
                push(`/reservation/calendar?roomTypeId=${roomType.id}`);
              } else {
                // setIsOpen(true);
                requireLoginPopup();
              }
            }}
            cardType={CardType.FULL}
          />
        ))}
      </div>
    </>
  );
};
