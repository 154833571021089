import React, { useEffect } from 'react';
import { Button } from '../components/Button';
import LodingImg from '../assets/img/maru.png';
import { useHistory } from 'react-router-dom';

export const LandingPage = () => {
  const { push } = useHistory();

  useEffect(() => {
    setTimeout(() => push('/home'), 5000);
  }, [push]);

  return (
    <>
      <img src={LodingImg} alt="" className="px-28 py-36 "></img>
      <div className="text-center">
        <Button
          type="button"
          onClick={() => push('/home')}
          text="SKIP"
          className="text-18 underline underline-offset-4"
        />

        <p className="text-12 text-gray-600 ">
          5초 후 다음 화면으로 이동가능합니다.
        </p>
      </div>
    </>
  );
};
