import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';

export const FindEmailSuccessPage = () => {
  const { push } = useHistory();
  const { state } = useLocation<any>();

  return (
    <>
      <TopBar text="이메일 찾기" />

      <div className="pl-6 py-8 text-brand-1 text-24 font-semibold">
        가입한 이메일 주소입니다.
      </div>

      <div className="pb-16">
        <TextField label="이메일" value={state.email} disabled />
      </div>

      <Button
        text="로그인하기"
        className="filled-brand-1 "
        onClick={() => push('/login')}
      />
    </>
  );
};
