import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { ModalAnimation } from '../../../components/modal/ModalAnimation';
import { Select } from '../../../components/Select';
import { TextArea } from '../../../components/TextArea';
import { adminApi } from '../../../plugins/axios';
import { Reservation } from '../../../types';

interface ReservationSmsModalProps {
  reservation: Reservation;
  open: boolean;
  onClose: () => void;
}

interface FormValues {
  smsType: string;
  content?: string;
}

export const ReservationSmsModal: FC<ReservationSmsModalProps> = ({
  reservation,
  open,
  onClose,
}) => {
  const { watch, setValue, register, handleSubmit } = useForm<FormValues>();

  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title="수동 문자발송"
        content={
          <div className="space-y-6">
            <Select
              value={watch('smsType')}
              onChange={(e) => setValue('smsType', e.target.value)}
            >
              <option value={''} selected disabled>
                발송 메세지를 선택해주세요.
              </option>
              <option value={'reservationCard'}>
                예약 신청 완료(카드결제)
              </option>
              <option value={'reservationBank'}>
                예약 신청 완료(무통장입금)
              </option>
              <option value={'reservationConfirm'}>예약 확정</option>
              <option value={'reservationCancel'}>취소 승인</option>
              <option value={'reservationCheckIn'}>체크인</option>
              <option value={'reservationCheckOut'}>체크아웃</option>
              <option value={'other'}>기타</option>
            </Select>
            {watch('smsType') === 'other' && (
              <TextArea
                className="h-60"
                placeholder="내용을 입력해주세요."
                {...register('content')}
              />
            )}
            <Button
              type="button"
              className="filled-brand-1 w-full"
              text="발송하기"
              onClick={handleSubmit(async (data) => {
                try {
                  await adminApi.post(
                    `/admin/reservations/sendSms/${reservation.id}`,
                    data
                  );
                  setValue('content', '');
                  toast.success('알림이 발송되었습니다.');
                } catch (e) {
                  toast.error('알림 발송 오류');
                }
              })}
            />
          </div>
        }
      />
    </>
  );
};
