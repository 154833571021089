import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { useQueryString } from '../../../hooks';
import { adminFetcher } from '../../../plugins/react-query';
import { Paginated, User } from '../../../types';
import { Search } from '../../components/Search';
import { TopNavBar } from '../../components/TopNavBar';

export const UserList = () => {
  const { push } = useHistory();
  const queryString = useQueryString();
  const [q, setQ] = useState('');

  // 유저 검색 및 유저 리스트
  const { data } = useQuery<Paginated<User>>(
    `/admin/users${queryString}&q=${q}`,
    adminFetcher,
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <TopNavBar title="회원관리" />
      <div className="p-6">
        <div className="grid grid-cols-3 gap-x-4">
          <div className=" col-span-2">
            <Search
              placeholder="검색"
              value={q}
              onChange={(e) => setQ(e.target.value)}
            />
          </div>
          <Button text="검색" className="h-full text-sm filled-brand-1" />
        </div>

        <Table>
          <Table.Body>
            {data?.items.map((user) => (
              <Table.Row
                key={user.id}
                onClick={() => push(`/admin/users/${user.id}`)}
                className="cursor-pointer"
              >
                <Table.Td>{user.name}</Table.Td>
                <Table.Td>
                  {(user?.phoneNumber || '010-1111-1111')
                    .toString()
                    .replace(
                      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                      '$1-$2-$3'
                    )}
                </Table.Td>
                <Table.Td>{user.email}</Table.Td>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        <Pagination>
          <Pagination.Label
            count={data?.items.length || 0}
            total={data?.total || 0}
          />
          <Pagination.Nav basePath="/admin/users" total={data?.total || 0} />
        </Pagination>
      </div>
    </>
  );
};
