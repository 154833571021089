import React from 'react';
import { TopBar } from '../../components/TopBar';
import marugallery from '../../assets/img/marugallery.png';
import haeugallery from '../../assets/img/haeugallery.png';
import { CardType, MainCard } from '../../components/MainCard';
import { useQuery } from 'react-query';
import { RoomType } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { useHistory } from 'react-router-dom';

export const GalleryRoomTypePage = () => {
  const { push } = useHistory();

  const { data: roomTypes } = useQuery<RoomType[]>('/room-types/all', fetcher);

  return (
    <>
      <TopBar text="GALLERY" />
      <div className="flex-1 relative flex flex-col">
        {roomTypes?.map((roomType, i) => (
          <MainCard
            key={i}
            src={roomType.id === 1 ? marugallery : haeugallery}
            onClick={() =>
              push(`/gallery/lounge/${roomType.id}`, { title: roomType.title })
            }
            cardType={CardType.FULL}
          />
        ))}
      </div>
    </>
  );
};
