import React, { useEffect } from 'react';
import { Button } from '../../../components/Button';
import { TopNavBar } from '../../components/TopNavBar';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { TextField } from '../../../components/TextField';
import { useQuery } from 'react-query';
import { RoomType } from '../../../types';
import { adminFetcher } from '../../../plugins/react-query';
import { useForm } from 'react-hook-form';
import { adminApi } from '../../../plugins/axios';
import { toast } from 'react-toastify';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { Checkbox } from '../../../components/Checkbox';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';

interface FormValues {
  adultAdditionalPrice: number;
  childrenAdditionalPrice: number;
  infantAdditionalPrice: number;
  consecutiveNights: number;
  discountRate: number;
  moreThanTwoNights: boolean;
  roomTypeId: number;
}

export const DiscountSet = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: roomTypes, refetch } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  useEffect(() => {
    if (!roomTypes || roomTypes.length === 0) return;
    const roomType = roomTypes.find((roomType) => roomType.id === roomTypeId);
    setValue('adultAdditionalPrice', roomType?.adultAdditionalPrice || 0);
    setValue('childrenAdditionalPrice', roomType?.childrenAdditionalPrice || 0);
    setValue('infantAdditionalPrice', roomType?.infantAdditionalPrice || 0);
    setValue('consecutiveNights', roomType?.consecutiveNights || 0);
    setValue('discountRate', roomType?.discountRate || 0);
    setValue('moreThanTwoNights', roomType?.moreThanTwoNights || false);
  }, [roomTypes, setValue, roomTypeId]);

  console.log(watch('moreThanTwoNights'));

  return (
    <>
      <TopNavBar title="할인/숙소 옵션 설정" />
      <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} />
      <div className="space-y-3 p-6 w-full">
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="focus:outline-none flex w-full justify-between py-2 font-medium border-b">
                <span>인원 추가 금액</span>
                <ChevronUpIcon
                  className={`${open ? '' : 'rotate-180 transform'} h-5 w-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 space-y-2">
                <TextField
                  label={
                    roomTypeId === 1
                      ? '성인 인원 추가금액 (기본1인, 2인부터 추가)'
                      : '성인 인원 추가금액 (기본2인, 3인부터 추가)'
                  }
                  type="number"
                  placeholder="성인 인원 추가금액을 입력해주세요."
                  helper={errors.adultAdditionalPrice?.message}
                  {...register('adultAdditionalPrice', {
                    required: '성인 인원 추가금액을 입력해주세요.',
                  })}
                />
                <TextField
                  label="아동 인원 추가금액"
                  type="number"
                  placeholder="아동 인원 추가금액을 입력해주세요."
                  {...register('childrenAdditionalPrice')}
                />
                <TextField
                  label="영아 인원 추가금액"
                  type="number"
                  placeholder="영아 인원 추가금액을 입력해주세요."
                  {...register('infantAdditionalPrice')}
                />
                <Button
                  text="저장하기"
                  className="filled-brand-1 w-full"
                  disabled={
                    !watch('adultAdditionalPrice') ||
                    !watch('childrenAdditionalPrice') ||
                    !watch('infantAdditionalPrice')
                  }
                  onClick={handleSubmit(async (data) => {
                    try {
                      data.roomTypeId = roomTypeId;
                      await adminApi.patch(
                        `/admin/room-types/option/setting`,
                        data
                      );
                      refetch();
                      toast.success('할인 옵션 설정이 완료되었습니다.');
                    } catch (e) {
                      toast.error('할인 옵션 설정 오류');
                    }
                  })}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="focus:outline-none flex w-full justify-between py-2 font-medium border-b">
                <span>연박 할인</span>
                <ChevronUpIcon
                  className={`${open ? '' : 'rotate-180 transform'} h-5 w-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 space-y-2">
                <TextField
                  label="기준 숙박일 (2박3일부터 적용이라면 ‘2’입력)"
                  type="number"
                  placeholder="기준 숙박일 을 입력해주세요."
                  {...register('consecutiveNights')}
                />
                <TextField
                  label="할인율"
                  type="number"
                  placeholder="할인율을 입력해주세요."
                  {...register('discountRate')}
                />
                <Button
                  text="저장하기"
                  className="filled-brand-1 w-full"
                  disabled={
                    !watch('consecutiveNights') || !watch('discountRate')
                  }
                  onClick={handleSubmit(async (data) => {
                    try {
                      data.roomTypeId = roomTypeId;
                      await adminApi.patch(
                        `/admin/room-types/option/setting`,
                        data
                      );
                      refetch();
                      toast.success('할인 옵션 설정이 완료되었습니다.');
                    } catch (e) {
                      toast.error('할인 옵션 설정 오류');
                    }
                  })}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="focus:outline-none flex w-full justify-between py-2 font-medium border-b">
                <span>최소 연박 2일 이상</span>
                <ChevronUpIcon
                  className={`${open ? '' : 'rotate-180 transform'} h-5 w-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 space-y-6">
                <Checkbox
                  label="최소 기준 숙박일(2박 이상)"
                  {...register('moreThanTwoNights')}
                  checked={watch('moreThanTwoNights')}
                />
                <Button
                  text="저장하기"
                  className="filled-brand-1 w-full"
                  onClick={handleSubmit(async (data) => {
                    try {
                      data.roomTypeId = roomTypeId;
                      await adminApi.patch(
                        `/admin/room-types/option/setting`,
                        data
                      );
                      refetch();
                      toast.success('할인 옵션 설정이 완료되었습니다.');
                    } catch (e) {
                      toast.error('할인 옵션 설정 오류');
                    }
                  })}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div className="flex-1" />
    </>
  );
};
