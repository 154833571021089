import { parse } from 'qs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Calendar } from '../../components/Calendar';
import { Loading } from '../../components/Loading';
import { TopBar } from '../../components/TopBar';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { fetcher } from '../../plugins/react-query';
import { RoomType, Timeslot } from '../../types';
import moment from 'moment';

export const ReservationCalendarPage = () => {
  const { search } = useLocation();
  const { roomTypeId } = parse(search, { ignoreQueryPrefix: true });
  const [isCheckIn, setIsCheckIn] = useState<boolean>(true);
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;

  const checkIn = localStorage.getItem('checkIn');
  const checkOut = localStorage.getItem('checkOut');

  const { data: roomType } = useQuery<RoomType>(
    `/room-types/${roomTypeId}`,
    fetcher,
    {
      enabled: !!roomTypeId,
    }
  );

  const { data: timeslots, refetch } = useQuery<Timeslot[]>(
    `/timeslots/roomTypeId/${roomTypeId}?startAt=${today
      .startOf('month')
      .toISOString()}&endAt=${today.endOf('month').toISOString()}`,
    fetcher,
    {
      enabled: !!roomTypeId && !!today,
    }
  );

  if (!roomType || !timeslots || timeslots.length === 0) {
    return <Loading />;
  }

  return (
    <div>
      <TopBar text="BOOKING" backButton storageClear />
      <Calendar
        checkIn={checkIn}
        checkOut={checkOut}
        isCheckIn={isCheckIn}
        timeslots={timeslots}
        today={today}
        getMoment={getMoment}
        setMoment={(moment: moment.Moment) => setMoment(moment)}
        refetch={() => refetch()}
        setIsCheckIn={(boolean: boolean) => setIsCheckIn(boolean)}
        roomType={roomType}
      />
      <div className="flex justify-end items-center space-x-5 px-5 text-12 mt-3">
        <div className="flex items-center space-x-1">
          <p className=" wh-4 bg-gray-300 rounded-xl" />
          <p>예약불가</p>
        </div>
        <div className="flex items-center space-x-1">
          <p className=" wh-4 bg-gray-800 rounded-xl" />
          <p>예약가능</p>
        </div>
      </div>
      <div className="h-10"></div>
      <div className="h-auto fixed bottom-0 w-full border-t-slate-50 border-t max-w-md bg-white">
        <div className="p-5 space-y-2 bg">
          <div className="text-20 font-semibold">
            {Number(roomTypeId) === 1 ? 'MARU' : 'HAEU'}
          </div>
          <div className="text-15 font-medium text-gray-500">
            <span
              className="cursor-pointer"
              onClick={() => {
                localStorage.removeItem('checkOut');
                setIsCheckIn(true);
              }}
            >
              {checkIn
                ? utcToLocalFormat(checkIn, MomentFormat.YYMMDD)
                : '체크인 날짜를 선택해주세요'}
            </span>
            <span> {!checkIn ? '' : '-'}</span>
            <span
              className="cursor-pointer"
              onClick={() => setIsCheckIn(false)}
            >
              {checkOut
                ? utcToLocalFormat(checkOut, MomentFormat.YYMMDD)
                : !checkIn
                ? ''
                : '체크아웃 날짜를 선택해주세요'}
            </span>
          </div>
        </div>
        <Button
          type="button"
          to={`/reservation/${roomTypeId}`}
          disabled={!checkIn || !checkOut}
          text="객실선택하기"
          className="filled-brand-1 h-16 w-full max-w-md"
        />
      </div>
    </div>
  );
};
