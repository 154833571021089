import React, { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { adminFetcher } from '../../../plugins/react-query';
import { Reservation, RoomType, Timeslot } from '../../../types';
import { Calendar } from '../../components/Calendar';
import moment, { Moment } from 'moment';
import { ReservationCard } from '../../components/ReservationCard';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

interface ReservationCalendarProps {
  roomType: RoomType;
}

export const ReservationCalendar: FC<ReservationCalendarProps> = ({
  roomType,
}) => {
  const { state } = useLocation<any>();
  const today = moment();
  const [selectDate, setSelectDate] = useState<string>(
    today.startOf('day').format()
  );
  const [month, setMonth] = useState(moment());

  const from = month.startOf('month').format('YYYY-MM-DD');
  const to = month.endOf('month').format('YYYY-MM-DD');

  const { data: timeslots } = useQuery<Timeslot[]>(
    `/admin/timeslots/roomTypeId/${
      !state ? roomType.id : state?.roomTypeId
    }?from=${from}&to=${to}`,
    adminFetcher
  );

  const { data: reservations } = useQuery<Reservation[]>(
    `/admin/reservations/roomType/${
      !state ? roomType.id : state?.roomTypeId
    }?selectedDate=${moment(selectDate).toISOString()}`,
    adminFetcher
  );

  // 선택한 날짜의 타임슬롯
  const findSelectTimeslots = timeslots?.filter(
    (timeslot) =>
      moment(timeslot.checkIn).startOf('day').format() === selectDate
  );

  // 타임슬롯 요일 구하기
  const findDay = moment().weekday();
  // 요일별 금액 보여주기
  const roomPrice =
    findSelectTimeslots && findSelectTimeslots[0]?.detailPrice
      ? findSelectTimeslots[0]?.detailPrice
      : findDay === 0
      ? roomType.sundayPrice
      : findDay === 5
      ? roomType.fridayPrice
      : findDay === 6
      ? roomType.saturdayPrice
      : roomType.weekdayPrice;

  return (
    <div className="space-y-5">
      <Calendar
        timeslots={timeslots}
        setSelectDate={(date: string) => setSelectDate(date)}
        setMonth={(date: Moment) => setMonth(date)}
      />
      <div className="flex justify-end items-center space-x-5 px-5 text-12 mt-3">
        <div className="flex items-center space-x-1">
          <p className="wh-3 bg-brand-3 rounded-xl" />
          <p>휴일</p>
        </div>
        <div className="flex items-center space-x-1">
          <p className="wh-3 bg-gray-800 rounded-xl" />
          <p>예약가능</p>
        </div>
      </div>

      <div className="bg-gray-50 h-12 flex justify-between items-center px-5">
        <p className="font-semibold text-17">
          {utcToLocalFormat(selectDate || '', MomentFormat.YYYYMMDD)}
        </p>
        <p className="text-gray-500 text-15">
          {roomPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
        </p>
      </div>
      {!reservations || reservations.length === 0 ? (
        <p>해당 날짜의 예약이 없습니다.</p>
      ) : (
        <>
          {reservations.map((reservation) => (
            <ReservationCard reservation={reservation} key={reservation.id} />
          ))}
        </>
      )}
    </div>
  );
};
