import { useEffect, useState } from 'react';
import { TopNavBar } from '../../components/TopNavBar';
import { Room, RoomType } from '../../../types';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { useQuery } from 'react-query';
import { adminFetcher } from '../../../plugins/react-query';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { EditModal } from '../../components/modal/editModal';
import { Button } from '../../../components/Button';
import { useHistory } from 'react-router-dom';
import { adminApi } from '../../../plugins/axios';
import { EditorRoom } from '../../../plugins/froala-room';
import { Select } from '../../../components/Select';

export const BookingInfoEditor = () => {
  const { push } = useHistory();
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const { data: roomTypes } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );
  const { data: roomData, refetch } = useQuery<Room[]>(
    `admin/rooms/info/${roomTypeId}`,
    adminFetcher,
    { enabled: !!roomTypeId }
  );
  const [reservationRoomInfo, setReservationRoomInfo] = useState('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  async function save() {
    const data: any = {
      reservationRoomInfo,
    };
    await adminApi
      .patch(`/admin/rooms/reserve/info/${roomId}`, data)
      .catch((e) => console.log(e));

    await refetch();
    setIsOpen(true);
    push(`/admin/rooms/reserve/info/editor`);
  }

  const [roomId, setRoomId] = useState<number>();
  const roomIdData = roomData?.find((x) => x.id === roomId);

  useEffect(() => {
    if (!roomIdData) setReservationRoomInfo('');
    if (roomIdData) setReservationRoomInfo(roomIdData.reservationRoomInfo);
  }, [roomIdData, setReservationRoomInfo]);

  useEffect(() => {
    if (roomTypeId == 2) setRoomId(NaN);
    if (roomTypeId == 1) setRoomId(NaN);
  }, [roomTypeId]);

  return (
    <>
      <TopNavBar title="예약 시 숙박별 정보 설정" />
      <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} />
      <div
        className="grid padding-1  p-6
      "
      >
        <Select
          value={roomId}
          onChange={(e) => setRoomId(Number(e.target.value))}
        >
          <option>방 선택하기</option>
          {roomData?.map((room) => (
            <option key={room.id} value={room.id}>
              {room.title}
            </option>
          ))}
        </Select>
      </div>
      <div className="space-y-3 p-6 w-full">
        <EditorRoom
          model={reservationRoomInfo}
          onModelChange={(model: any) => {
            setReservationRoomInfo(model);
          }}
        />
      </div>
      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <EditModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          refetch={() => refetch()}
        />
        <Button
          disabled={!roomId}
          text={reservationRoomInfo ? '수정하기' : '생성하기'}
          className="filled-brand-1 w-full"
          onClick={save}
        />
      </div>
    </>
  );
};
