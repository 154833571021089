import React from 'react';
import { Description } from '../../components/Description';
import { FooterBar } from '../../components/FooterBar';
import { TopBar } from '../../components/TopBar';

export const VoucherPage = () => {
  return (
    <>
      <TopBar text="VOUCHER" backButton />
      <div className="p-6">
        <Description
          title="바우처"
          desc={`인생의 모든 기억들은 언제, 혹은 어딘가에서부터 시작된다. 그 기억은 위치와 시간, 또는 계절 정도로 흐릿한 추억일지도 모르겠다.

2019년, 10개월 간 떠나온 유라시아 횡단 중 러시아 시베리아 구간을 건너던 어느 날 이였다. 쉬지 않고 열여섯 시간을 운전하고는 지쳐 갓길에 차량을 세웠다. 드넓은 초원 어디쯤 이였는데 지평선 너머로 붉은 노을이 지고 있었다. 대단할 것도 없는 노을인데 한참을 멍하니 서있었다. 십 여분 쯤 지났을까? 지친 몸은 서서히 회복되었고 그날 만큼 몸과 마음이 맑아본 적이 없었다.`}
        />
      </div>
      <FooterBar />
    </>
  );
};
