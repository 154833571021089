import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { adminFetcher } from '../../../plugins/react-query';
import { PointHistory } from '../../../types';
import { PointAddModal } from '../../components/modal/PointAddModal';
import { PointCard } from '../../components/PointCard';

export const UserPoint = () => {
  const { id } = useParams<{ id: string }>();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: pointHistories, refetch } = useQuery<PointHistory[]>(
    `/admin/point-histories/user/${id}`,
    adminFetcher,
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );

  return (
    <div className="p-6">
      <PointAddModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        userId={Number(id)}
        refetch={() => refetch()}
      />
      <Button
        text="관리자 지급/차감 하기"
        className="filled-brand-1 w-full"
        onClick={() => setIsOpen(true)}
      />
      <div className="flex justify-between items-center text-brand-1 py-3 mt-7">
        <p className="text-sm">현재 보유 적립금</p>
        <p className="text-xl font-semibold">
          {(
            (pointHistories &&
              pointHistories.length > 0 &&
              pointHistories[0]?.user.totalPoint) ||
            0
          )
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          원
        </p>
      </div>

      {pointHistories &&
        pointHistories.length > 0 &&
        pointHistories.map((pointHistory) => (
          <PointCard pointHistory={pointHistory} key={pointHistory.id} />
        ))}
    </div>
  );
};
