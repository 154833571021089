import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import { PhoneNumber } from '../../components/PhoneNumber';
import { useForm } from 'react-hook-form';
import { api } from '../../plugins/axios';
import { toast } from 'react-toastify';

interface FormValues {
  name: string;
  phoneNumber: string;
}

export const FindPasswordPage = () => {
  const { push } = useHistory();
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <TopBar text="비밀번호 변경" backButton />

      <div className="pl-6 py-8 text-brand-1 text-24 font-semibold">
        비밀번호 변경을 위해 <br />
        핸드폰 인증을 해주세요.
      </div>

      <form className="p-6 flex flex-col space-y-4">
        <TextField
          label="이름"
          type="name"
          placeholder="이름을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', { required: '이름을 입력해주세요' })}
        />

        <PhoneNumber
          label="휴대폰 인증"
          phoneNum={phoneNum}
          setPhoneNum={setPhoneNum}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
          skip
        />

        <Button
          type="button"
          onClick={handleSubmit(async (data) => {
            data.phoneNumber = phoneNum;
            await api
              .post('/users/find/password', data)
              .then((res) => {
                if (res.data) {
                  push(`/find/password/reset`, { resetToken: res.data });
                } else {
                  toast.error('가입된 정보가 없습니다.');
                }
              })
              .catch((e) => console.log('error ', e));
          })}
          text="다음"
          disabled={!isVerified || !watch('name')}
          className="filled-brand-1"
        />
      </form>
    </>
  );
};
