import React, { FC, HTMLAttributes } from 'react';
import { useHistory } from 'react-router-dom';

export enum CardType {
  HIGH = 'high',
  MIDDLE = 'MIDDLE',
  FULL = 'FULL',
}

export interface MainCardProps extends HTMLAttributes<HTMLDivElement> {
  text1?: string;
  text2?: string;
  src?: string;
  link?: string;
  onClick?: () => void;
  bgColor?: 'brown' | 'lightGray';
  cardType?: CardType;
}

export const MainCard: FC<MainCardProps> = ({
  src,
  text1,
  text2,
  link,
  bgColor,
  onClick,
  cardType = CardType.HIGH,
}) => {
  const { push } = useHistory();
  return (
    <>
      <div
        className={`relative ${
          cardType === CardType.HIGH
            ? 'h-48'
            : cardType === CardType.MIDDLE
            ? 'h-32'
            : cardType === CardType.FULL && 'flex-1'
        } ${bgColor === 'brown' ? 'bg-[#302826]' : 'bg-[#D6D2CF]'}`}
        onClick={link ? () => push(link) : onClick}
      >
        <div className="absolute bottom-6 left-6 z-10">
          <p className="text-white text-14">{text1}</p>
          <p className="text-white text-24 font-medium tracking-widest">
            {text2}
          </p>
        </div>
        {src && (
          <img
            src={src}
            alt=""
            className="w-full h-full object-cover absolute"
          />
        )}
      </div>
    </>
  );
};
