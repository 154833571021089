import moment from 'moment';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { Reservation, ReservationState } from '../../types';

export interface ReservationCardProps {
  reservation: Reservation;
}

export const ReservationCard: FC<ReservationCardProps> = ({ reservation }) => {
  const { push } = useHistory();

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    reservation?.checkIn &&
    reservation?.checkOut &&
    moment(reservation?.checkOut).diff(moment(reservation?.checkIn), 'days');

  return (
    <>
      <div
        className="p-6 space-y-2 border-b cursor-pointer"
        onClick={() => push(`/admin/detail/reserve/${reservation.id}`)}
      >
        <div className="flex items-center space-x-3">
          <h4 className="text-lg font-semibold">
            {reservation.roomTypeTitle} - {reservation.roomTitle}
          </h4>
          <p
            className={`w-max px-3 py-1 rounded-full text-sm ${
              reservation.state === ReservationState.BEFORE_DEPOSIT ||
              reservation.state === ReservationState.CANCELED ||
              reservation.state === ReservationState.USED
                ? 'bg-gray-100 text-gray-600'
                : reservation.state ===
                  ReservationState.APPLICATION_FOR_RESERVATION
                ? 'text-blue-500 bg-blue-100'
                : reservation.state ===
                  ReservationState.RESERVATION_CONFIRMATION
                ? 'text-green-500 bg-green-100'
                : reservation.state === ReservationState.CANCELLATION_REQUEST &&
                  'text-red-500 bg-red-100'
            }`}
          >
            {reservation.state === ReservationState.CANCELED
              ? '취소완료'
              : reservation.state === ReservationState.CANCELLATION_REQUEST
              ? '취소신청'
              : reservation.state ===
                ReservationState.APPLICATION_FOR_RESERVATION
              ? '예약신청'
              : reservation.state === ReservationState.RESERVATION_CONFIRMATION
              ? '예약완료'
              : reservation.state === ReservationState.BEFORE_DEPOSIT
              ? '입금 전'
              : reservation.state === ReservationState.USED
              ? '이용 완료'
              : ''}
          </p>
        </div>
        <div>
          <div className="flex items-center space-x-3 font-semibold">
            <p>
              {utcToLocalFormat(
                reservation.checkIn.toLocaleString(),
                MomentFormat.YYYYMMDD
              )}{' '}
              -{' '}
              {utcToLocalFormat(
                reservation.checkOut.toLocaleString(),
                MomentFormat.YYYYMMDD
              )}
            </p>
            <p className="text-blue-500">
              {countingNights}박 {Number(countingNights) + 1}일
            </p>
          </div>
          <div className="flex items-center space-x-3 font-semibold">
            <p className="">{reservation.reservationName}</p>
            <p>
              {reservation.reservationPhoneNumber
                ?.toString()
                .replace(
                  /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
                  '$1-$2-$3'
                )}
            </p>
          </div>
          <div className="flex items-center space-x-1">
            <p>
              {reservation.paymentOrder.totalPrice
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              원
            </p>
            <p>•</p>
            <p>
              {reservation.paymentOrder.usedPoint
                ?.toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              P 사용
            </p>
            <p>•</p>
            <p>
              {reservation.paymentOrder.finalPrice
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              원 결제
            </p>
          </div>
          <p>
            결제일{' '}
            {reservation.paidAt
              ? utcToLocalFormat(
                  reservation.paidAt.toLocaleString(),
                  MomentFormat.YYYYMMDDHmmss
                )
              : '-'}
          </p>
          <p>
            취소 신청일{' '}
            {(reservation.state === ReservationState.CANCELLATION_REQUEST ||
              reservation.state === ReservationState.CANCELED) &&
            reservation.cancelRequestedAt
              ? utcToLocalFormat(
                  reservation.cancelRequestedAt.toLocaleString(),
                  MomentFormat.YYYYMMDDHmmss
                )
              : '-'}
          </p>
          <p>{reservation.memo}</p>
          <p className="text-gray-500">
            성인 {reservation.adult}명 / 아동{' '}
            {reservation.child ? reservation.child : 0}명 / 영아{' '}
            {reservation.infant ? reservation.infant : 0}명
          </p>
        </div>
      </div>
    </>
  );
};
