import { ComponentType, FC } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route } from 'react-router';
import { useAuth } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { Role, User } from '../types';

export interface AuthRouteProps {
  path: string | string[];
  component: ComponentType;
  redirectTo?: string;
  guestOnly?: boolean;
  guestOnlyRedirectTo?: string;
}

export const AuthRoute: FC<AuthRouteProps> = ({
  path,
  component: Component,
  redirectTo = '/login',
  guestOnly = false,
}) => {
  const { authenticated } = useAuth();

  const { data: me, status } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  return (
    <Route
      path={path}
      render={() => {
        if (status === 'loading') return <></>;
        if (guestOnly && authenticated) {
          return (
            <Redirect
              to={
                status === 'success' && me && me.role === Role.ADMIN
                  ? '/admin/reserve/real-time'
                  : '/'
              }
            />
          );
        }
        if (!guestOnly && !authenticated) {
          return <Redirect to={redirectTo} />;
        }

        return <Component />;
      }}
    />
  );
};
