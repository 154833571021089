import { FC } from 'react';
import { Tab } from '../../components/Tab';
import { pageTitles } from '../../dummies';
import { tabSelectPageTitleState } from '../../plugins/ridge';

interface PageTitleTabProps {
  pageTitle: string;
  refetch: () => void;
}

export const PageTitleTab: FC<PageTitleTabProps> = ({ pageTitle, refetch }) => {
  return (
    <div className="w-full flex border-b">
      {pageTitles?.map((page, i) => (
        <Tab
          key={i}
          text={page.title}
          selected={pageTitle === page.title}
          onClick={async () => {
            tabSelectPageTitleState.set(page.title);
            await refetch();
          }}
          hasBorder
          className="flex-1 py-2"
        />
      ))}
    </div>
  );
};
