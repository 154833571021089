import React, { useState } from 'react';
import { BottomNavBar } from '../../components/BottomNavBar';
import { TopBar } from '../../components/TopBar';
import { FooterBar } from '../../components/FooterBar';
import { DefaultModal } from '../../components/modal/DefaultModal';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useQuery } from 'react-query';
import { Description, PageTitle } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { tabSelectPageTitleState } from '../../plugins/ridge';

export const RentMain = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  tabSelectPageTitleState.set(PageTitle.RENT);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );

  return (
    <>
      <DefaultModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="준비중입니다."
        description="곧 오픈될 예정이니, 조금만 기다려주세요!"
      />
      <TopBar text="RENT" backButton />
      <FroalaEditorView model={description?.content} />
      <FooterBar />
      <BottomNavBar />
    </>
  );
};
