import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from './Icon';

export interface TopBarProps {
  storageClear?: boolean;
  backButton?: boolean;
  text: string;
}

export const TopBar: FC<TopBarProps> = ({ storageClear, backButton, text }) => {
  const { goBack } = useHistory();
  return (
    <div className="sticky top-0 bg-white z-10">
      {backButton && (
        <button
          onClick={
            storageClear
              ? () => {
                  localStorage.removeItem('checkIn');
                  localStorage.removeItem('checkOut');
                  goBack();
                }
              : goBack
          }
          className="absolute left-4 top-4"
        >
          <Icon.ChevronLeft />
        </button>
      )}

      <div className="flex justify-center p-4 border-b tracking-widest">
        {text}
      </div>
    </div>
  );
};
