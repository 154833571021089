import React from 'react';
import { TopBar } from '../../components/TopBar';
import { FooterBar } from '../../components/FooterBar';
import { Button } from '../../components/Button';
// import { useAuth } from '../../hooks';
// import { usePopup } from '../../components/PopUp';
import { useHistory } from 'react-router-dom';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useQuery } from 'react-query';
import { Description, PageTitle } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { tabSelectPageTitleState } from '../../plugins/ridge';

export const HaeuMain = () => {
  // const { authenticated } = useAuth();
  const { push } = useHistory();
  // const { requireLoginPopup } = usePopup();
  tabSelectPageTitleState.set(PageTitle.HAEU);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );
  return (
    <>
      <TopBar text="HAEU" backButton />
      <FroalaEditorView model={description?.content} />
      <FooterBar />

      <div className="pt-16">
        <Button
          type="button"
          text="예약하기"
          onClick={() => {
            // if (authenticated) {
            push('/reservation');
            // } else {
            //   requireLoginPopup();
            // }
          }}
          className="filled-brand-1 h-16 fixed bottom-20 w-full max-w-md"
        />
      </div>
    </>
  );
};
