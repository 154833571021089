import React, { FC } from 'react';
import { Tab } from '../../components/Tab';
import { tabSelectRoomTypeIdState } from '../../plugins/ridge';
import { RoomType } from '../../types';

interface RoomTypeTabProps {
  roomTypes?: RoomType[];
  roomTypeId: number;
}

export const RoomTypeTab: FC<RoomTypeTabProps> = ({
  roomTypes,
  roomTypeId,
}) => {
  return (
    <div className="w-full flex border-b">
      {roomTypes?.map((roomType, i) => (
        <Tab
          key={i}
          text={roomType.title}
          selected={roomTypeId === roomType.id}
          onClick={() => tabSelectRoomTypeIdState.set(roomType.id)}
          hasBorder
          className="flex-1 py-2"
        />
      ))}
    </div>
  );
};
