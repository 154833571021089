import React from 'react';
import { BottomNavBar } from '../../components/BottomNavBar';
import { TopBar } from '../../components/TopBar';
import { FooterBar } from '../../components/FooterBar';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useQuery } from 'react-query';
import { Description, PageTitle } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { tabSelectPageTitleState } from '../../plugins/ridge';

export const ContactMain = () => {
  tabSelectPageTitleState.set(PageTitle.CONTACT);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );

  return (
    <>
      <TopBar text="CONTACT" backButton />
      <FroalaEditorView model={description?.content} />
      <FooterBar />
      <BottomNavBar />
    </>
  );
};
