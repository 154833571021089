import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Select } from '../../../components/Select';
import { adminFetcher } from '../../../plugins/react-query';
import { Reservation, ReservationState } from '../../../types';
import { ReservationCard } from '../../components/ReservationCard';

export const UserReservation = () => {
  const { id } = useParams<{ id: string }>();
  const [reservationState, setReservationState] = useState<
    ReservationState | string
  >('');

  const { data: reservations } = useQuery<Reservation[]>(
    `/admin/reservations/user/${id}?state=${reservationState}`,
    adminFetcher,
    {
      enabled: !!id,
      keepPreviousData: true,
    }
  );

  return (
    <>
      <div className="px-6 pt-6 pb-3">
        <Select
          value={reservationState}
          onChange={(e) => setReservationState(e.target.value)}
        >
          <option value={''} selected>
            전체상태
          </option>
          <option value={ReservationState.BEFORE_DEPOSIT}>입금전</option>
          <option value={ReservationState.APPLICATION_FOR_RESERVATION}>
            예약신청
          </option>
          <option value={ReservationState.RESERVATION_CONFIRMATION}>
            예약확정
          </option>
          <option value={ReservationState.CANCELLATION_REQUEST}>
            취소신청
          </option>
          <option value={ReservationState.CANCELED}>취소완료</option>
          <option value={ReservationState.USED}>이용완료</option>
        </Select>
      </div>
      {reservations && reservations?.length > 0 ? (
        reservations?.map((reservation, index) => (
          <ReservationCard key={index} reservation={reservation} />
        ))
      ) : (
        <div className="text-center my-8 text-gray-500 text-15">
          현재 방문예정인 숙소가 없거나,
          <br />
          해당 상태의 예약 내역이 없습니다.
        </div>
      )}
    </>
  );
};
