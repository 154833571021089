import moment from 'moment';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { ModalAnimation } from '../../../components/modal/ModalAnimation';
import { TextField } from '../../../components/TextField';
import { adminApi } from '../../../plugins/axios';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { PaymentMethod, Reservation } from '../../../types';

interface ReservationCancelModalProps {
  reservation: Reservation;
  open: boolean;
  refetch: () => void;
  onClose: () => void;
}

interface FormValues {
  price: number;
  point?: number;
}

export const ReservationCancelModal: FC<ReservationCancelModalProps> = ({
  reservation,
  open,
  refetch,
  onClose,
}) => {
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  // 방문일 기준 전일 구하기
  const remainingDays = moment
    .duration(moment(reservation.checkIn).diff(moment().format()))
    .asDays();
  const remainingDayCount = Math.ceil(remainingDays);

  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title="예약 취소하기"
        content={
          <div className="space-y-6">
            <div className="pb-6 border-b">
              <p>
                {utcToLocalFormat(reservation.checkIn, MomentFormat.YMD)} -{' '}
                {utcToLocalFormat(reservation.checkOut, MomentFormat.YMD)}{' '}
                {reservation?.roomTypeTitle} {reservation?.roomTitle}
              </p>
              <p>
                취소 신청 일자{' '}
                <span className="font-semibold text-gray-800">
                  {reservation.canceledAt
                    ? utcToLocalFormat(
                        reservation.canceledAt,
                        MomentFormat.YYYYMMDD
                      )
                    : '-'}
                </span>
              </p>
              <p>
                방문일자 기준{' '}
                <span className="font-semibold text-gray-800">
                  {remainingDayCount <= 0 ? '-' : <>{remainingDayCount}일전</>}
                </span>
              </p>
              <p>
                결제수단{' '}
                <span className="font-semibold text-gray-800">
                  {reservation.paymentOrder.paymentMethod === PaymentMethod.CARD
                    ? '카드결제'
                    : reservation.paymentOrder.paymentMethod ===
                      PaymentMethod.BANK
                    ? '무통장 입금'
                    : reservation.paymentOrder.paymentMethod ===
                      PaymentMethod.K_PAY
                    ? '카카오 페이'
                    : reservation.paymentOrder.paymentMethod ===
                      PaymentMethod.N_PAY
                    ? '네이버 페이'
                    : '-'}
                </span>
              </p>
            </div>
            <div className="pb-6 border-b">
              <p>
                적립금 사용{' '}
                <span className="font-semibold">
                  {(reservation?.paymentOrder?.usedPoint)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  P사용
                </span>
              </p>
              <p>
                실결제금액{' '}
                <span className="font-semibold">
                  {reservation.paymentOrder.finalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </span>
              </p>
              <p className="text-blue-500">
                총 결제금액{' '}
                <span className="font-semibold">
                  {reservation.paymentOrder.totalPrice
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  원
                </span>
              </p>
            </div>
            <TextField
              label="결제 취소할 금액"
              placeholder="취소할 금액을 입력해주세요."
              helper={errors.price?.message}
              {...register('price', {
                required: '취소할 금액을 입력해주세요.',
              })}
            />
            <TextField
              label="반환 적립금"
              placeholder="반환 적립금을 입력해주세요."
              {...register('point')}
            />

            <Button
              type="button"
              className="filled-brand-1 w-full"
              text="취소하기"
              onClick={handleSubmit(async (data) => {
                data.point = !watch('point') ? 0 : watch('point');
                try {
                  await adminApi.patch(
                    `/admin/payment-orders/cancel/reservation/${reservation.id}`,
                    data
                  );
                  refetch();
                  toast.success('예약이 취소되었습니다.');
                } catch (err: any) {
                  toast.error(err.response.data.message);
                }
              })}
            />
          </div>
        }
      />
    </>
  );
};
