import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { adminApi } from '../../../plugins/axios';
import { adminFetcher } from '../../../plugins/react-query';
import { tabSelectRoomTypeIdState } from '../../../plugins/ridge';
import { RoomType } from '../../../types';
import { RoomTypeTab } from '../../components/RoomTypeTab';
import { TopNavBar } from '../../components/TopNavBar';

interface FormValues {
  weekdayPrice: number;
  fridayPrice: number;
  saturdayPrice: number;
  sundayPrice: number;
  roomTypeId: number;
}

export const DefaultDepositSet = () => {
  const roomTypeId = tabSelectRoomTypeIdState.useValue();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { data: roomTypes, refetch } = useQuery<RoomType[]>(
    '/admin/room-types/all',
    adminFetcher
  );

  useEffect(() => {
    if (!roomTypes || roomTypes.length === 0) return;
    const roomType = roomTypes.find((roomType) => roomType.id === roomTypeId);
    setValue('weekdayPrice', roomType?.weekdayPrice || 0);
    setValue('fridayPrice', roomType?.fridayPrice || 0);
    setValue('saturdayPrice', roomType?.saturdayPrice || 0);
    setValue('sundayPrice', roomType?.sundayPrice || 0);
  }, [roomTypes, setValue, roomTypeId]);

  return (
    <>
      <TopNavBar title="기본 요금 설정" />
      <RoomTypeTab roomTypes={roomTypes} roomTypeId={roomTypeId} />
      <div className="space-y-3 p-6">
        <h3 className="text-xl font-semibold">기본 요금</h3>
        <TextField
          label="평일요금(월~목)"
          type="number"
          placeholder="평일요금을 입력해주세요."
          helper={errors.weekdayPrice?.message}
          {...register('weekdayPrice', {
            required: '평일요금을 입력해주세요.',
          })}
        />
        <TextField
          label="금요일"
          type="number"
          placeholder="금요일을 입력해주세요."
          helper={errors.fridayPrice?.message}
          {...register('fridayPrice', {
            required: '금요일을 입력해주세요.',
          })}
        />
        <TextField
          label="토요일(공휴일 전날)"
          type="number"
          placeholder="토요일(공휴일 전날)을 입력해주세요."
          helper={errors.saturdayPrice?.message}
          {...register('saturdayPrice', {
            required: '토요일(공휴일 전날)을 입력해주세요.',
          })}
        />
        <TextField
          label="일요일"
          type="number"
          placeholder="일요일을 입력해주세요."
          helper={errors.sundayPrice?.message}
          {...register('sundayPrice', {
            required: '일요일을 입력해주세요.',
          })}
        />
      </div>
      <div className="flex-1" />

      <div className="p-6 sticky bottom-0">
        <Button
          text="저장하기"
          className="filled-brand-1 w-full"
          onClick={handleSubmit(async (data) => {
            try {
              data.roomTypeId = roomTypeId;
              await adminApi.patch(`/admin/room-types/price/setting`, data);
              refetch();
              toast.success('기본 요금 설정이 완료되었습니다.');
            } catch (e) {
              toast.error('기본 요금 설정 오류');
            }
          })}
        />
      </div>
    </>
  );
};
