import React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { FooterBar } from '../../components/FooterBar';
import { ReservationCard } from '../../components/ReservationCard';
import { TopBar } from '../../components/TopBar';
import { useAuth } from '../../hooks';
import { fetcher } from '../../plugins/react-query';
import { meState } from '../../plugins/ridge';
import { Reservation } from '../../types';

export const MyPage = () => {
  const { push } = useHistory();
  const { logout } = useAuth();
  const me = meState.useValue();

  const { data: reservations } = useQuery<Reservation[]>(
    `/reservations/my/confirmation`,
    fetcher,
    {
      enabled: !!me,
    }
  );

  if (!me) {
    return <></>;
  }
  return (
    <>
      <TopBar text="MY PAGE" />
      <div className="p-6 space-y-3 border-b border-gray-100">
        <div className="flex space-x-4 items-center">
          <h3 className="text-xl font-medium">반가워요, {me.name}님</h3>
          <p className="text-gray-500 text-sm">|</p>
          <p
            className="text-gray-500 text-sm cursor-pointer"
            onClick={() => push('/my/edit')}
          >
            내 정보 수정
          </p>
        </div>
        <div
          className="flex space-x-4 items-center text-sm cursor-pointer"
          onClick={() => push('/my/point')}
        >
          <p className="text-gray-500">적립금</p>
          <p className="text-gray-500">|</p>
          <h3 className="font-medium">
            {(me?.totalPoint || 0)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            원
          </h3>
        </div>
      </div>

      <div className="p-6">
        <h3 className="text-lg font-semibold">방문예정</h3>
        {reservations ? (
          <>
            {reservations.map((reservation) => (
              <ReservationCard reservation={reservation} key={reservation.id} />
            ))}
          </>
        ) : (
          <div className="text-center my-6 text-gray-500 text-15">
            현재 방문예정인 숙소가 없거나,
            <br />
            관리자 예약 확인중 입니다.
          </div>
        )}
      </div>

      <div className="h-3 bg-gray-50" />

      <div
        className="text-sm py-4 mx-6 border-b border-gray-100 cursor-pointer"
        onClick={() => push('/my/reservations')}
      >
        예약 정보
      </div>
      <div
        className="text-sm py-4 mx-6 border-b border-gray-100 cursor-pointer"
        onClick={() => logout()}
      >
        로그아웃
      </div>
      <FooterBar />
    </>
  );
};
