import React, { FC, useState } from 'react';

export interface DescriptionProps {
  title?: string;
  subTitle?: string;
  desc?: string;
  className?: string;
  hide?: boolean;
  src?: string;
}

export const Description: FC<DescriptionProps> = ({
  src,
  title,
  subTitle,
  desc,
  className,
  hide = false,
}) => {
  const [isOpen] = useState<boolean>(false);
  return (
    <div className={`${className} py-4`}>
      {title && (
        <p className="text-24 font-semibold tracking-widest mb-6">{title}</p>
      )}

      {subTitle && (
        <p className="text-16 font-semibold mb-2 text-gray-700">{subTitle}</p>
      )}
      <p
        className={`text-14 text-gray-600 whitespace-pre-line ${
          hide && !isOpen && 'line-clamp-15 overflow-hidden'
        }`}
      >
        {src && <img src={src} alt="" className="pb-4" />}
        {desc === '@atin.maru' ? (
          <a
            href="https://www.instagram.com/atin.maru/"
            target="_blank"
            rel="noopener noreferrer"
          >
            @atin.maru
          </a>
        ) : (
          <> {desc}</>
        )}
      </p>
    </div>
  );
};
