import _ from 'lodash';
import { groupBy } from 'lodash';
import moment from 'moment';
import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { RoomType, Timeslot } from '../types';

interface CalendarProps {
  checkIn?: string | null;
  checkOut?: string | null;
  isCheckIn?: boolean;
  timeslots?: Timeslot[];
  roomType?: RoomType;
  today: moment.Moment;
  getMoment: moment.Moment;
  setMoment: (moment: moment.Moment) => void;
  refetch?: () => void;
  setIsCheckIn?: (boolean: boolean) => void;
}

export const Calendar: FC<CalendarProps> = ({
  checkIn,
  checkOut,
  isCheckIn,
  timeslots,
  roomType,
  today,
  getMoment,
  setMoment,
  refetch,
  setIsCheckIn,
}) => {
  // 캘린더 날짜 및 요일 설정
  const firstWeek = today.clone().startOf('month').week();
  const lastWeek =
    today.clone().endOf('month').week() === 1
      ? 53
      : today.clone().endOf('month').week();
  const dates = ['일', '월', '화', '수', '목', '금', '토'];
  const alreadyCheckIn = localStorage.getItem('checkIn');
  const alreadyCheckOut = localStorage.getItem('checkOut');

  // 체크인 날짜 선택 시 가장 가까운 예약 안되는 날 구하기
  const groupByTimeslots = groupBy(timeslots, 'checkIn');
  const impossibleTimeslots = _.map(
    groupByTimeslots,
    (timeslots: any, key: any) => {
      const findImpossibleTimeslots = timeslots.filter(
        (timeslot: any) => !timeslot.isOpened || timeslot.isReserved
      );
      if (findImpossibleTimeslots.length === timeslots.length) {
        return { timeslots, key };
      }
    }
  );
  const nearImpossibleTimeslots = impossibleTimeslots.find(
    (timeslot) => timeslot && timeslot?.key > moment(checkIn).toISOString()
  );

  // 캘린더 부분
  const calendarArr = () => {
    let result: any[] = [];
    for (let week = firstWeek; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week} className="border-1 border bg-neutral-100">
          {Array(7)
            .fill(0)
            .map((_, index) => {
              let day = today
                .clone()
                .startOf('year')
                .week(week)
                .startOf('week')
                .add(index, 'day');

              // 해당 날짜에 맞는 timeslot 찾기
              const isSameTimeslots = timeslots?.filter((timeslot) =>
                day.isSame(timeslot.checkIn, 'date')
              );
              // 모든 객실이 막혀있는 경우
              const isOpenedTimeslots = isSameTimeslots?.filter(
                (timeslot) => !timeslot.isOpened
              );
              // 객실이 막혀있거나 예약이 되어 있는 경우
              const impossibleTimeslots = isSameTimeslots?.filter(
                (timeslot) => !timeslot.isOpened || timeslot.isReserved
              );
              // 현재 달의 일과 맞지 않은 경우
              const noMatchMonthDay = day.format('MM') !== today.format('MM');

              // 오늘 기준 지난 날
              const theOldDays =
                moment().format('YYYYMMDD') > day.format('YYYYMMDD');

              // 체크인 날짜 선택 기준 이전 날
              const checkInTheOldDay =
                day.isBefore(checkIn, 'date') &&
                alreadyCheckIn &&
                !alreadyCheckOut;
              // 예약이 불가능한 체크인 기준 가까운날을 제외한 예약 불가능한 날
              const impossibleDay =
                impossibleTimeslots?.length === isSameTimeslots?.length &&
                nearImpossibleTimeslots?.key !== moment(day).toISOString();
              //  예약이 불가능한 체크인 기준 가까운날 이후 날들
              const laterDays =
                alreadyCheckIn &&
                !alreadyCheckOut &&
                day.isAfter(
                  moment(nearImpossibleTimeslots?.key).toISOString(),
                  'date'
                ) &&
                nearImpossibleTimeslots?.key;

              // 선택한 날짜 및 기본 날짜 다음의 timeslot 가져오기
              const selectTimeslots = timeslots?.filter((timeslot) => {
                if (alreadyCheckIn && alreadyCheckOut) {
                  return (
                    timeslot.checkIn ===
                    moment(day.format()).add(1, 'day').toISOString()
                  );
                } else if (alreadyCheckIn) {
                  return (
                    timeslot.checkIn ===
                    moment(alreadyCheckIn).add(1, 'day').toISOString()
                  );
                } else {
                  return (
                    timeslot.checkIn ===
                    moment(day.format()).add(1, 'day').toISOString()
                  );
                }
              });

              // 선택한 날짜 및 기본 날짜 다음의 날짜가 예약 가능한지 확인
              const possibleTimeslots = selectTimeslots?.filter(
                (timeslot) => timeslot.isOpened && !timeslot.isReserved
              );

              // 체크인  선택 시 다음의 날짜가 예약 가능한지 확인
              const checkInMoreThanTwoNights =
                roomType?.moreThanTwoNights &&
                (!possibleTimeslots || possibleTimeslots.length === 0) &&
                !alreadyCheckIn;

              // 체크아웃 선택 시 다음의 날짜가 예약 가능한지 확인
              const checkOutMoreThanTwoNights =
                roomType?.moreThanTwoNights &&
                (!possibleTimeslots || possibleTimeslots.length === 0);

              if (
                noMatchMonthDay ||
                theOldDays ||
                checkInTheOldDay ||
                impossibleDay ||
                laterDays ||
                (alreadyCheckIn && checkInMoreThanTwoNights)
              ) {
                return (
                  <td
                    key={index}
                    className="text-gray-300 h-13 border-1 border"
                  >
                    <span
                      className={`${
                        isOpenedTimeslots?.length === isSameTimeslots?.length &&
                        'line-through'
                      }`}
                    >
                      {day.format('D')}
                    </span>
                  </td>
                );
              } else {
                return (
                  <td
                    key={index}
                    className={`h-13 cursor-pointer border-1 border 
                    ${
                      day.isSameOrAfter(checkIn, 'date') &&
                      day.isSameOrBefore(checkOut, 'date')
                        ? 'bg-gray-800 text-white'
                        : day.isSame(checkIn, 'date')
                        ? 'bg-gray-800 text-white'
                        : day.isSame(checkOut, 'date')
                        ? 'bg-gray-800 text-white'
                        : 'text-gray-800'
                    }`}
                    onClick={() => {
                      // 체크인 시작
                      if (isCheckIn) {
                        if (alreadyCheckIn && checkOutMoreThanTwoNights) {
                          toast.error(
                            '연박부터 예약이 가능합니다. 최소 2이상 선택해주세요.'
                          );
                          return;
                        }
                        if (alreadyCheckIn) {
                          localStorage.removeItem('checkIn');
                          localStorage.removeItem('checkOut');
                        }
                        if (
                          impossibleTimeslots?.length ===
                            isSameTimeslots?.length &&
                          day.isSame(
                            isSameTimeslots && isSameTimeslots[0].checkIn,
                            'date'
                          )
                        ) {
                          toast.error('체크아웃만 선택이 가능한 날짜입니다.');
                        } else {
                          localStorage.setItem('checkIn', day.format());
                          setIsCheckIn && setIsCheckIn(false);
                        }
                      }
                      // 체크아웃 시작
                      else {
                        if (alreadyCheckOut) {
                          localStorage.removeItem('checkOut');
                        }
                        if (checkIn === day.format()) {
                          localStorage.removeItem('checkIn');
                          localStorage.removeItem('checkOut');
                          setIsCheckIn && setIsCheckIn(true);
                        } else if (
                          roomType?.moreThanTwoNights &&
                          day.isSame(
                            moment(alreadyCheckIn).add(1, 'day').toISOString(),
                            'date'
                          )
                        ) {
                          toast.error(
                            '연박부터 예약이 가능합니다. 최소 2이상 선택해주세요.'
                          );
                        } else {
                          // 선택한 timeslot 가져오기
                          const selectTimeslots = timeslots?.filter(
                            (timeslot) =>
                              timeslot.checkIn >=
                                moment(
                                  new Date(alreadyCheckIn || '')
                                ).toISOString() &&
                              timeslot.checkIn <
                                moment(
                                  new Date(day.format() || '')
                                ).toISOString()
                          );
                          // 선택한 timeslot 중 불가능 한 timeslot 계산
                          const selectTimeslotCheckIns = selectTimeslots?.map(
                            (timeslot) => timeslot.checkIn
                          );
                          const possibleTimeslots = selectTimeslots?.filter(
                            (timeslot) =>
                              timeslot.isOpened && !timeslot.isReserved
                          );
                          const possibleCheckIns = possibleTimeslots?.map(
                            (timeslot) => timeslot.checkIn
                          );
                          const setSelectTimeslots = new Set(
                            selectTimeslotCheckIns
                          );
                          const setPossibleTimeslots = new Set(
                            possibleCheckIns
                          );

                          // 선택한 날짜 중 불가능 한 예약이 있으면 예약 불가 처리
                          if (
                            setSelectTimeslots.size ===
                            setPossibleTimeslots.size
                          ) {
                            localStorage.setItem('checkOut', day.format());
                            setIsCheckIn && setIsCheckIn(true);
                          } else {
                            localStorage.removeItem('checkIn');
                            localStorage.removeItem('checkOut');
                            setIsCheckIn && setIsCheckIn(true);
                            toast.error('예약이 불가능한 날짜입니다.');
                          }
                        }
                      }
                    }}
                  >
                    <span>{day.format('D')}</span>
                  </td>
                );
              }
            })}
        </tr>
      );
    }
    return result;
  };

  return (
    <div className="px-4 ">
      <div className="text-center ">
        <div className="text-center my-6 space-x-10 text-19 font-semibold text-gray-700 ">
          <button
            className=" w-12 h-8"
            onClick={() => {
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
            disabled={moment().isSame(today, 'month')}
          >
            {`<`}
          </button>

          <span>{today.format('YYYY 년 MM 월')}</span>

          <button
            className=" w-12 h-10"
            onClick={() => {
              const isAfterThreeMonth =
                getMoment
                  .endOf('month')
                  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  .format() ===
                moment()
                  .endOf('month')
                  .add(3, 'month')
                  .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                  .format();

              if (isAfterThreeMonth) {
                alert('4개월 이후까지의 예약만 가능합니다.');
              } else {
                setMoment(getMoment.clone().add(1, 'month'));
              }
            }}
          >
            {`>`}
          </button>
        </div>

        <table className="w-full h-screen-30">
          <tbody>
            <tr className="bg-brand-1 h-11">
              {dates.map((date, idx) => (
                <td key={idx} className="text-white border-1 border">
                  <span>{date}</span>
                </td>
              ))}
            </tr>
          </tbody>
          <tbody className="h-full">{calendarArr()}</tbody>
        </table>
      </div>
    </div>
  );
};
