import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { ModalAnimation } from '../../../components/modal/ModalAnimation';
import { PointHistoryType } from '../../../types';

interface FormValues {
  pointType: PointHistoryType;
  title: string;
  value: number;
  memo: string;
  userId: number;
}

interface PointAddModalProps {
  open: boolean;

  onClose: () => void;
  refetch: () => void;
}

export const EditModal: FC<PointAddModalProps> = ({
  open,
  onClose,
  refetch,
}) => {
  const { handleSubmit } = useForm<FormValues>();

  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title="저장되었습니다"
        content={
          <Button
            type="button"
            className="filled-brand-1 w-full"
            text="완료"
            onClick={handleSubmit(async (data) => {
              await onClose();
            })}
          />
        }
      />
    </>
  );
};
