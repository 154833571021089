import React from 'react';
import { useHistory } from 'react-router';
import { popupState } from '../plugins/ridge';
import { Button } from './Button';
import { Icon } from './Icon';

export interface PopUpProps {
  title?: string;
  body?: any;
  secondBody?: any;
  primaryText?: string;
  primaryClick?: () => void;
  secondaryText?: string;
  secondaryClick?: () => void;
  dangerText?: string;
  dangerClick?: () => void;
  footer?: any;
}

export const PopUp = () => {
  const popup = popupState.useValue();

  if (!popup.title) return <></>;
  return (
    <div className="z-50 fixed inset-0 bg-littleblack flex items-center justify-center px-4">
      <div className="card max-h-screen-15">
        <div className="flex justify-between items-start">
          <h1 className="font-bold text-20 whitespace-pre">{popup.title}</h1>
          <button onClick={popupState.reset}>
            <Icon.X />
          </button>
        </div>

        {popup.body && (
          <div className="mt-4 text-14 text-gray-700 whitespace-pre-line">
            {popup.body}
          </div>
        )}

        {popup.secondBody && (
          <div className="mt-6 text-11 text-gray-400 whitespace-pre-line">
            {popup.secondBody}
          </div>
        )}

        <div className="mt-12 flex flex-col space-y-3">
          {popup.primaryText && (
            <Button
              text={popup.primaryText}
              className="filled-brand-1 text-14"
              onClick={() => {
                popupState.reset();
                popup.primaryClick?.();
              }}
            />
          )}
          {popup.secondaryText && (
            <Button
              text={popup.secondaryText}
              className="bg-secondary-1 text-brand-1 text-14"
              onClick={() => {
                popupState.reset();
                popup.secondaryClick?.();
              }}
            />
          )}
          {popup.dangerText && (
            <Button
              text={popup.dangerText}
              className="bg-red-50 text-red-600 text-14"
              onClick={() => {
                popupState.reset();
                popup.dangerClick?.();
              }}
            />
          )}
        </div>

        {popup.footer && (
          <div className="mt-4 text-17 text-gray-700">{popup.footer}</div>
        )}
      </div>
    </div>
  );
};

export function usePopup() {
  const { push } = useHistory();

  const requireLoginPopup = () =>
    popupState.set({
      title: '로그인이 필요한 서비스입니다.',
      body: '로그인이 필요한 서비스입니다.\n로그인 페이지로 이동하시겠습니까?',
      primaryText: '로그인 페이지로 이동하기',
      primaryClick: () => push('/login'),
    });

  return {
    requireLoginPopup,
  };
}
