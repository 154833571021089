import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import { Timeslot } from '../../types';

interface DetailDepositCalendarProps {
  timeslots?: Timeslot[];
  setSelectDate: (data: string) => void;
  setMonth?: (data: Moment) => void;
}

export const DetailDepositCalendar: FC<DetailDepositCalendarProps> = ({
  timeslots,
  setSelectDate,
  setMonth,
}) => {
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;
  const [selectedDate, setSelectedDate] = useState<string>(
    today.startOf('day').format()
  );

  // 캘린더 날짜 및 요일 설정
  const firstWeek = today.clone().startOf('month').week();
  const lastWeek =
    today.clone().endOf('month').week() === 1
      ? 53
      : today.clone().endOf('month').week();
  const dates = ['일', '월', '화', '수', '목', '금', '토'];

  // 캘린더 부분
  const calendarArr = () => {
    let result: any[] = [];
    for (let week = firstWeek; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
          {Array(7)
            .fill(0)
            .map((_, index) => {
              let days = today
                .clone()
                .startOf('year')
                .week(week)
                .startOf('week')
                .add(index, 'day');

              const isSameTimeslot = timeslots?.filter(
                (timeslot) =>
                  days.isSame(timeslot.checkIn, 'date') && timeslot.isOpened
              );

              if (
                days.format('MM') !== today.format('MM') ||
                moment().format('YYYYMMDD') > days.format('YYYYMMDD')
              ) {
                return (
                  <td key={index} className="text-gray-300 cursor-pointer">
                    <span>{days.format('D')}</span>
                  </td>
                );
              } else {
                return (
                  <td
                    key={index}
                    className={`cursor-pointer ${
                      selectedDate === days.format()
                        ? 'font-bold text-gray-900'
                        : isSameTimeslot && isSameTimeslot.length > 0
                        ? 'text-gray-900'
                        : 'text-brand-3'
                    }`}
                  >
                    <span
                      onClick={() => {
                        setSelectedDate(days.format());
                        setSelectDate(days.format());
                      }}
                    >
                      {days.format('D')}
                    </span>
                    <div className="text-11 w-11 mx-auto">
                      {(
                        (isSameTimeslot && isSameTimeslot[0]?.detailPrice) ||
                        ''
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  </td>
                );
              }
            })}
        </tr>
      );
    }
    return result;
  };

  return (
    <div className="px-4">
      <div className="text-center">
        <div className="text-center my-9 space-x-20 text-19 font-semibold text-gray-700">
          <button
            onClick={() => {
              setMonth && setMonth(getMoment.clone().subtract(1, 'month'));
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
          >
            {`<`}
          </button>
          <span>{today.format('YYYY 년 MM 월')}</span>
          <button
            onClick={() => {
              setMonth && setMonth(getMoment.clone().add(1, 'month'));
              setMoment(getMoment.clone().add(1, 'month'));
            }}
          >
            {`>`}
          </button>
        </div>

        <table className="w-full h-screen-35">
          <tbody>
            <tr>
              {dates.map((date, idx) => (
                <td key={idx} className="text-gray-400">
                  <span>{date}</span>
                </td>
              ))}
            </tr>
          </tbody>
          <tbody>{calendarArr()}</tbody>
        </table>
      </div>
    </div>
  );
};
