import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Tab } from '../../../components/Tab';
import { TopNavBar } from '../../components/TopNavBar';
import { UserInfo } from './UserInfo';
import { UserPoint } from './UserPoint';
import { UserReservation } from './UserReservation';

export const UserMain = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <TopNavBar title="회원 상세" />
      <div className="w-full flex border-b mt-3">
        <Tab
          text={'회원정보'}
          selected={pathname === `/admin/users/${id}/info`}
          onClick={() => push(`/admin/users/${id}/info`)}
          hasBorder
          className="flex-1 py-2"
        />
        <Tab
          text={'예약내역'}
          selected={pathname === `/admin/users/${id}/reserve`}
          onClick={() => push(`/admin/users/${id}/reserve`)}
          hasBorder
          className="flex-1 py-2"
        />
        <Tab
          text={'적립금내역'}
          selected={pathname === `/admin/users/${id}/point`}
          onClick={() => push(`/admin/users/${id}/point`)}
          hasBorder
          className="flex-1 py-2"
        />
      </div>
      <Switch>
        <Route path="/admin/users/:id/point" component={UserPoint} />
        <Route path="/admin/users/:id/reserve" component={UserReservation} />
        <Route path="/admin/users/:id/info" component={UserInfo} />
        <Redirect from={`/admin/users/${id}`} to={`/admin/users/${id}/info`} />
      </Switch>
    </>
  );
};
