import React from 'react';
import atinlogo from '../assets/img/atinlogo.png';

export const FooterBar = () => {
  return (
    <>
      <div className="">
        <div className="border-t border-gray-700 px-6 py-6 bg-neutral-100">
          <img src={atinlogo} className="h-4" alt="logo" />

          <div className="text-13 space-x-3 mt-5">
            <a
              href="https://prairie-porcupine-6b9.notion.site/f7d49d7c21bd4460afa3a7f47502bf3d"
              target="_blank"
              rel="noreferrer"
            >
              <span className="font-bold">이용약관</span>
            </a>
            <a
              href="https://prairie-porcupine-6b9.notion.site/f61a5788a25a422da43dac0ab6156b2a"
              target="_blank"
              rel="noreferrer"
            >
              <span className="font-bold">개인정보취급방침</span>
            </a>
          </div>

          <p className="text-12 pt-4 text-gray-700">
            Copyright ⓒ 2013. BK architecture. all rights reserved. <br />
            사업자등록번호 321-26-01264
            <br />
            통신판매업신고 번호 : 제 2022-경기양평-0128호 <br />
            경기도 양평군 서종면 (상세주소는 예약일 오전 9시에 문자메세지로
            발송됩니다.)
          </p>
        </div>
      </div>
    </>
  );
};
