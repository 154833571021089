import moment from 'moment';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { haueRoomThumbnails, maruRoomThumbnails } from '../dummies';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Reservation } from '../types';
import { Icon } from './Icon';

export interface ReservationCardProps {
  reservation: Reservation;
}

export const ReservationCard: FC<ReservationCardProps> = ({ reservation }) => {
  const { push } = useHistory();

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    reservation?.checkIn &&
    reservation?.checkOut &&
    moment(reservation?.checkOut).diff(moment(reservation?.checkIn), 'days');

  // 숙소 썸네일
  const roomThumbnail =
    reservation.roomTypeId === 1
      ? maruRoomThumbnails.find(
          (thumbnail) => thumbnail.roomId === reservation.roomId
        )
      : haueRoomThumbnails.find(
          (thumbnail) => thumbnail.roomId === reservation.roomId
        );
  return (
    <>
      <div
        className="py-3 space-y-2"
        onClick={() => push(`/my/reservation/${reservation.id}`)}
      >
        <div className="flex items-center justify-between">
          <div className="space-y-1">
            <p className="text-gray-600">{reservation.roomTitle}</p>
            <p className="font-semibold">
              {utcToLocalFormat(reservation.checkIn, MomentFormat.LLdd)} -{' '}
              {utcToLocalFormat(reservation.checkOut, MomentFormat.LLdd)}
            </p>
            <p className="text-gray-600">
              {countingNights}박 {Number(countingNights) + 1}일
            </p>
          </div>
          <Icon.ChevronRight />
        </div>
        <div className="aspect-[5/3] relative">
          <img
            src={roomThumbnail?.image}
            alt=""
            className="w-full h-full object-cover absolute"
          />
        </div>
      </div>
    </>
  );
};
