import moment, { Moment } from 'moment';
import React, { FC, useState } from 'react';
import { ReservationState, Timeslot } from '../../types';

interface CalendarProps {
  timeslots?: Timeslot[];
  setSelectDate: (data: string) => void;
  setMonth?: (data: Moment) => void;
}

export const Calendar: FC<CalendarProps> = ({
  timeslots,
  setSelectDate,
  setMonth,
}) => {
  const [getMoment, setMoment] = useState(moment());
  const today = getMoment;
  const [selectedDate, setSelectedDate] = useState<string>(
    today.startOf('day').format()
  );

  // 캘린더 날짜 및 요일 설정
  const firstWeek = today.clone().startOf('month').week();
  const lastWeek =
    today.clone().endOf('month').week() === 1
      ? 53
      : today.clone().endOf('month').week();
  const dates = ['일', '월', '화', '수', '목', '금', '토'];

  // 캘린더 부분
  const calendarArr = () => {
    let result: any[] = [];
    for (let week = firstWeek; week <= lastWeek; week++) {
      result = result.concat(
        <tr key={week}>
          {Array(7)
            .fill(0)
            .map((_, index) => {
              let days = today
                .clone()
                .startOf('year')
                .week(week)
                .startOf('week')
                .add(index, 'day');

              const isSameTimeslot = timeslots?.filter((timeslot) =>
                days.isSame(timeslot.checkIn, 'date')
              );
              const isReservedTimeslot = isSameTimeslot?.filter(
                (timeslot) => timeslot.isReserved
              );
              const isOpenedTimeslot = isSameTimeslot?.filter(
                (timeslot) => timeslot.isOpened
              );

              if (
                days.format('MM') !== today.format('MM') ||
                moment().format('YYYYMMDD') > days.format('YYYYMMDD')
              ) {
                return (
                  <td
                    key={index}
                    className={`${
                      selectedDate === days.format()
                        ? 'text-white bg-black'
                        : 'text-gray-300'
                    }`}
                  >
                    <div>
                      <span
                        onClick={() => {
                          setSelectedDate(days.format());
                          setSelectDate(days.format());
                        }}
                      >
                        {days.format('D')}
                      </span>
                    </div>
                    {isSameTimeslot?.map((timeslot) => {
                      return (
                        <div className="text-10 whitespace-pre border">
                          {timeslot.reservation ? (
                            <>{timeslot.reservation?.reservationName}</>
                          ) : timeslot.isOpened ? (
                            '예약 전'
                          ) : (
                            '휴일'
                          )}
                        </div>
                      );
                    })}
                  </td>
                );
              } else {
                return (
                  <td
                    key={index}
                    className={`${
                      selectedDate === days.format()
                        ? 'bg-gray-100'
                        : isReservedTimeslot && isReservedTimeslot.length === 4
                        ? 'text-gray-800'
                        : isOpenedTimeslot && isOpenedTimeslot.length > 0
                        ? 'text-black'
                        : 'text-brand-3'
                    }`}
                  >
                    <div>
                      <span
                        onClick={() => {
                          setSelectedDate(days.format());
                          setSelectDate(days.format());
                        }}
                      >
                        {days.format('D')}
                      </span>
                    </div>
                    {isSameTimeslot?.map((timeslot) => {
                      return (
                        <div
                          className={`text-10 whitespace-pre border ${
                            !timeslot.isOpened
                              ? 'text-brand-3'
                              : timeslot.reservation?.state ===
                                ReservationState.APPLICATION_FOR_RESERVATION
                              ? 'text-blue-500 bg-blue-100'
                              : timeslot.reservation?.state ===
                                ReservationState.RESERVATION_CONFIRMATION
                              ? 'text-green-500 bg-green-100'
                              : timeslot.reservation?.state ===
                                ReservationState.BEFORE_DEPOSIT
                              ? 'bg-gray-100 text-gray-600'
                              : timeslot.reservation?.state ===
                                ReservationState.USED
                              ? 'text-gray-300'
                              : timeslot.reservation?.state ===
                                ReservationState.CANCELLATION_REQUEST
                              ? 'bg-red-200 text-red-600'
                              : ''
                          }`}
                        >
                          {timeslot.reservation ? (
                            <>{timeslot.reservation?.reservationName}</>
                          ) : timeslot.isOpened ? (
                            '예약 전'
                          ) : (
                            '휴일'
                          )}
                        </div>
                      );
                    })}
                  </td>
                );
              }
            })}
        </tr>
      );
    }
    return result;
  };

  return (
    <div className="px-4">
      <div className="text-center">
        <div className="text-center my-9 space-x-20 text-19 font-semibold text-gray-700">
          <button
            onClick={() => {
              setMonth && setMonth(getMoment.clone().subtract(1, 'month'));
              setMoment(getMoment.clone().subtract(1, 'month'));
            }}
          >
            {`<`}
          </button>
          <span>{today.format('YYYY 년 MM 월')}</span>
          <button
            onClick={() => {
              setMonth && setMonth(getMoment.clone().add(1, 'month'));
              setMoment(getMoment.clone().add(1, 'month'));
            }}
          >
            {`>`}
          </button>
        </div>

        <table className="w-full h-screen-35 border">
          <tbody>
            <tr>
              {dates.map((date, idx) => (
                <td key={idx} className="text-gray-400 border w-11">
                  <span>{date}</span>
                </td>
              ))}
            </tr>
          </tbody>
          <tbody>{calendarArr()}</tbody>
        </table>
      </div>
    </div>
  );
};
