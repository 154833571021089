import React from 'react';
import loadingImg from '../assets/img/loading.gif';

export const Loading = () => {
  return (
    <div className="flex justify-center items-center min-h-screen">
      <img src={loadingImg} alt="loading" className=" wh-14" />
    </div>
  );
};
