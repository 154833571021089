import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { HamburgerButton } from './HamburgerButton';

interface TopNavBarProps {
  title: string;
  subTitle?: string;
  onClick?: () => void;
}

export const TopNavBar: React.FC<TopNavBarProps> = ({
  title,
  subTitle,
  onClick,
}) => {
  const { logout } = useAuth();
  const history = useHistory();
  const [dropdown, setDropdown] = useState(false);

  const onPush = (to: string) => {
    history.push(`${to}`);
    setDropdown(false);
  };

  return (
    <>
      {/* 모바일용 TopNavBar */}
      <>
        <div className="flex justify-between items-center px-5 py-2 w-full border-b bg-white sticky top-0 z-20">
          <HamburgerButton
            className="z-10"
            open={dropdown}
            setOpen={() => setDropdown(!dropdown)}
          />
          <p className="absolute left-1/2 transform -translate-x-1/2">
            {title}
          </p>
          {subTitle && (
            <p
              onClick={onClick}
              className="text-sm text-gray-700 cursor-pointer"
            >
              {subTitle}
            </p>
          )}
        </div>
        <div className="">
          <div
            className={`${
              dropdown ? 'block' : 'hidden'
            } h-full bg-black w-full fixed inset-0 opacity-60 z-30 overflow-y-hidden`}
          />
          <div
            className={`${
              dropdown
                ? 'ase-in-out duration-500 opacity-100'
                : 'ease-in duration-250 opacity-0 pointer-events-none'
            } bg-white w-full fixed top-0 z-30 max-w-md`}
          >
            <div className="px-5 py-2 w-full border-b border-gray-800 relative flex items-center">
              <HamburgerButton
                className="z-50"
                open={dropdown}
                setOpen={() => setDropdown(!dropdown)}
              />
              <div className="absolute left-1/2 transform -translate-x-1/2">
                ADMIN
              </div>
            </div>
            <div className="flex flex-col space-y-4 p-8">
              <div className="space-y-2">
                <b>예약관리</b>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/reserve/real-time')}
                >
                  실시간 예약현황
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/reserve/calendar')}
                >
                  전체 예약현황
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/reserve/holiday')}
                >
                  방막기 / 방열기
                </div>
              </div>

              <div className="space-y-2">
                <b>숙소관리</b>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/rooms/charge/default')}
                >
                  기본 요금 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/rooms/charge/detail')}
                >
                  세부 요금 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/rooms/discount')}
                >
                  할인/숙소 옵션 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/rooms/option')}
                >
                  결제시 추가 옵션 설정
                </div>
              </div>
              <div className="space-y-2">
                <b>페이지 관리</b>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/description/editor')}
                >
                  메인 페이지 관리 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/gallery/editor')}
                >
                  갤러리 관리 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/rooms/info/editor')}
                >
                  숙박 정보 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/rooms/reserve/info/editor')}
                >
                  예약 시 숙박별 정보 설정
                </div>
                <div
                  className="pl-4 cursor-pointer"
                  onClick={() => onPush('/admin/reserve/policy/editor')}
                >
                  예약 취소 환불 정책 설정
                </div>
              </div>

              <b
                className="cursor-pointer"
                onClick={() => onPush('/admin/users')}
              >
                회원관리
              </b>

              <b className="cursor-pointer" onClick={logout}>
                로그아웃
              </b>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
