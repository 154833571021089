import React, { FC, InputHTMLAttributes, useState } from 'react';
import { TextField } from './TextField';
import { useId } from 'react-id-generator';
import { Button } from './Button';
import { Label } from './Label';
import { api } from '../plugins/axios';
import { MobileVerification } from '../types';

export interface PhoneNumberProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  phoneNum?: string;
  skip?: boolean;
  setPhoneNum: (v: string) => void;
  isVerified: boolean;
  setIsVerified: (v: boolean) => void;
  setPhoneValue?: (v: string) => void;
}

export const PhoneNumber: FC<PhoneNumberProps> = ({
  label,
  onChange,
  phoneNum,
  skip,
  setPhoneNum,
  isVerified,
  setIsVerified,
  setPhoneValue,
  ...props
}) => {
  const [id] = useId(1, 'phonenumber');
  const [number1, setNumber1] = useState<string>('');
  const [number2, setNumber2] = useState<string>('');
  const [number3, setNumber3] = useState<string>('');
  const [verifyNum, setVerifyNum] = useState<string>('');
  const [codeSent, setCodeSent] = useState(false);

  const createPhone = async (e: any) => {
    e.preventDefault();
    const phoneNumber = number1 + number2 + number3;

    await api
      .post<MobileVerification>(`/mobile-verifications`, {
        number: phoneNumber,
        isSkip: skip ? true : false,
      })
      .then(() => {
        setIsVerified(false);
        setCodeSent(true);
        alert('인증번호를 발송했습니다.');
      })
      .catch((err) => alert(`${err.response.data.message}`));
  };

  const verifyPhone = async (e: any) => {
    e.preventDefault();
    const phoneNumber = number1 + number2 + number3;

    await api
      .post<MobileVerification>(`/mobile-verifications/verify`, {
        number: phoneNumber,
        code: verifyNum,
      })
      .then((res) => {
        setIsVerified(res.data.verified);
        setPhoneNum(phoneNumber);
        alert('휴대폰 번호 인증에 성공했습니다.');
      })
      .catch((err) => {
        console.error(err);
        alert(`${err.response.data.message}`);
      });
  };

  return (
    <div className="label-col">
      {label && <Label htmlFor={id} text={label} />}
      <div className="grid grid-cols-3 gap-4 pb-2">
        <TextField
          id={id}
          type="tel"
          pattern="[0-9]{3}"
          maxLength={3}
          placeholder={phoneNum?.substring(0, 3) || '010'}
          value={number1}
          disabled={codeSent || isVerified}
          onChange={(e) => {
            setNumber1(e.target.value);
            e.target.value = `${e.target.value}${number2}${number3}`;
            setPhoneNum && number1.length > 0 && setPhoneNum(e.target.value);
            setPhoneValue &&
              number1.length > 0 &&
              setPhoneValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={phoneNum?.substring(3, 7) || '0000'}
          value={number2}
          disabled={codeSent || isVerified}
          onChange={(e) => {
            setNumber2(e.target.value);
            e.target.value = `${number1}${e.target.value}${number3}`;
            setPhoneNum && number2.length > 0 && setPhoneNum(e.target.value);
            setPhoneValue &&
              number2.length > 0 &&
              setPhoneValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
        <TextField
          type="tel"
          pattern="[0-9]{4}"
          maxLength={4}
          placeholder={phoneNum?.substring(7, 11) || '0000'}
          value={number3}
          disabled={codeSent || isVerified}
          onChange={(e) => {
            setNumber3(e.target.value);
            e.target.value = `${number1}${number2}${e.target.value}`;
            setPhoneNum && number3.length > 0 && setPhoneNum(e.target.value);
            setPhoneValue &&
              number3.length > 0 &&
              setPhoneValue(e.target.value);
            onChange?.(e);
          }}
          {...props}
        />
      </div>
      <div className="space-y-4">
        {!isVerified && (
          <Button
            type="button"
            className={`outlined-gray-900 w-full`}
            text={`${codeSent ? '인증번호 재전송' : '인증번호 전송'}`}
            onClick={(e) => createPhone(e)}
            disabled={!number1 || !number2 || !number3}
          />
        )}

        {codeSent && (
          <>
            <div className="flex space-x-3 w-full">
              <TextField
                placeholder="인증번호를 입력해주세요"
                value={verifyNum}
                disabled={isVerified}
                onChange={(e) => setVerifyNum(e.target.value)}
                className="w-full"
              />
              {isVerified && (
                <span className="text-13 text-blue-500">
                  인증이 완료되었습니다.
                </span>
              )}

              <Button
                type="button"
                text="확인"
                disabled={isVerified}
                className={`w-1/4 ${
                  verifyNum && 'bg-brand-1 text-white'
                } filled-gray-300 h-12`}
                onClick={(e) => verifyPhone(e)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
