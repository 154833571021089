import { FC } from 'react';
import { Button } from '../Button';
import { ModalAnimation } from './ModalAnimation';

interface DefaultModalProps {
  title: string;
  description?: any;
  buttonText?: string;
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
}

export const DefaultModal: FC<DefaultModalProps> = ({
  title,
  description,
  buttonText = '확인',
  open,
  onClick,
  onClose,
}) => {
  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title={title}
        content={
          <div className="space-y-6">
            <p className="whitespace-pre-line">{description}</p>
            {onClick && (
              <Button
                type="button"
                className="filled-brand-1 w-full"
                text={buttonText}
                onClick={onClick}
              />
            )}
          </div>
        }
      />
    </>
  );
};
