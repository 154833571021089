import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { LoginPage } from './pages/LoginPage';
import { ReservationMain } from './pages/reserve/ReservationMain';
import { ReservationDetail } from './pages/reserve/ReservationDetail';
import { ReservationHolidayMain } from './pages/reserve/ReservationHolidayMain';
import { DefaultDepositSet } from './pages/roomSet/DefaultDepositSet';
import { DetailDepositSet } from './pages/roomSet/DetailDepositSet';
import { DiscountSet } from './pages/roomSet/DiscountSet';
import { OptionSet } from './pages/roomSet/OptionSet';
import { OptionAdd } from './pages/roomSet/OptionAdd';
import { OptionDetail } from './pages/roomSet/OptionDetail';
import { UserList } from './pages/users/UserList';
import { UserMain } from './pages/users/UserMain';
import { ReservationRealTime } from './pages/reserve/ReservationRealTime';
import { HomePageEditor } from './pages/pageSet/HomePageEditor';
import { GalleryPageEditor } from './pages/pageSet/GalleryPageEditor';
import { GalleryAdd } from './pages/pageSet/GalleryAdd';
import { GalleryEdit } from './pages/pageSet/GalleryEdit';
import { InfoEditor } from './pages/roomSet/InfoEditor';
import { ReservationPolicyEditor } from './pages/reserve/ReservationPolicyEditor';
import { BookingInfoEditor } from './pages/roomSet/BookingInfoEditor';

export const Admin = () => {
  return (
    <>
      <div className="min-h-screen max-w-md mx-auto flex flex-col">
        <main className="relative flex-1 flex flex-col h-full">
          {/* <TopNavBar title="예약현황" subTitle="리스트보기" /> */}
          <Switch>
            {/* <Route path="/admin/users/:id/edit" component={UserEdit} />
            <Route path="/admin/users/:id" component={UserShow} /> */}
            <Route path="/admin/users/:id" component={UserMain} />
            <Route path="/admin/users" component={UserList} />
            <Route
              path="/admin/description/editor"
              component={HomePageEditor}
            />
            <Route path="/admin/gallery/editor" component={GalleryPageEditor} />
            <Route path="/admin/gallery/add" component={GalleryAdd} />
            <Route path="/admin/gallery/edit/:id" component={GalleryEdit} />
            <Route path="/admin/rooms/option/add" component={OptionAdd} />
            <Route path="/admin/rooms/option/:id" component={OptionDetail} />
            <Route path="/admin/rooms/option" component={OptionSet} />
            <Route path="/admin/rooms/discount" component={DiscountSet} />
            <Route path="/admin/rooms/info/editor" component={InfoEditor} />
            <Route
              path="/admin/rooms/charge/detail"
              component={DetailDepositSet}
            />
            <Route
              path="/admin/rooms/charge/default"
              component={DefaultDepositSet}
            />
            <Route
              path="/admin/reserve/holiday"
              component={ReservationHolidayMain}
            />
            <Route
              path="/admin/rooms/reserve/info/editor"
              component={BookingInfoEditor}
            />
            <Route
              path="/admin/detail/reserve/:id"
              component={ReservationDetail}
            />
            <Route
              path="/admin/reserve/real-time"
              component={ReservationRealTime}
            />
            <Route
              path="/admin/reserve/policy/editor"
              component={ReservationPolicyEditor}
            />
            <Route path="/admin/reserve" component={ReservationMain} />
            <Route path="/admin/login" component={LoginPage} />
            <Redirect from="/" to="/admin/reserve/real-time" />
          </Switch>
        </main>
      </div>
    </>
  );
};
