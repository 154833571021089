import React, { FC } from 'react';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import { PointHistory, PointHistoryType } from '../../types';

export interface PointCardProps {
  pointHistory: PointHistory;
}

export const PointCard: FC<PointCardProps> = ({ pointHistory }) => {
  return (
    <>
      <div className="text-gray-700 space-y-1 py-3 border-b">
        <p>
          {utcToLocalFormat(
            pointHistory.createdAt.toLocaleString(),
            MomentFormat.YYYYMMDDHmm
          )}
        </p>
        <div
          style={{ wordBreak: 'keep-all' }}
          className="flex justify-between items-center text-base"
        >
          <p className="">{pointHistory.title}</p>
          <p className="font-semibold whitespace-pre">
            {pointHistory.pointType === PointHistoryType.DEDUCTIBLE ? '-' : '+'}
            {pointHistory.value
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            원
          </p>
        </div>
        <p className="text-sm">{pointHistory.memo}</p>
      </div>
    </>
  );
};
