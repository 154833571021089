import React, { ButtonHTMLAttributes, FC } from 'react';

interface ChipProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  active?: boolean;
}

export const Chip: FC<ChipProps> = ({ text, active = false, ...props }) => {
  return (
    <button
      className={`button outlined-brand-1 h-10 ${
        active && 'text-white bg-brand-1 '
      }`}
      {...props}
    >
      {text}
    </button>
  );
};
