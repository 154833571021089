import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { Icon } from '../../components/Icon';

interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: () => void;
}

export const Search: FC<SearchProps> = ({
  value,
  onSearch = () => {},
  ...props
}) => {
  const input = useRef<HTMLInputElement>(null);
  const handleSearch = () => {
    onSearch();
  };

  return (
    <form
      className="flex w-full items-center px-4 h-12 border-2 border-brand-1 bg-white placeholder-gray-400 text-gray-700"
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch();
      }}
    >
      <input
        ref={input}
        className="flex-1 w-full text-sm "
        value={value}
        {...props}
      />
      <div className="flex justify-center items-center -ml-2 wh-10">
        <Icon.Search
          onClick={() => {
            handleSearch();
          }}
        />
      </div>
    </form>
  );
};
