import React from 'react';
import { useQuery } from 'react-query';
import { PointCard } from '../../components/PointCard';
import { TopBar } from '../../components/TopBar';
import { fetcher } from '../../plugins/react-query';
import { meState } from '../../plugins/ridge';
import { PointHistory } from '../../types';

export const MyPointsPage = () => {
  const me = meState.useValue();

  const { data: pointHistories } = useQuery<PointHistory[]>(
    '/point-histories/my',
    fetcher
  );

  return (
    <>
      <TopBar text="적립금" backButton />
      <div className="px-6 py-5 flex justify-between items-center bg-brand-1 text-white">
        <p className="text-sm font-light">현재 보유 적립금</p>
        <p className="text-lg font-semibold">
          {me?.totalPoint.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
        </p>
      </div>

      <div className="px-6">
        {pointHistories &&
          pointHistories.length > 0 &&
          pointHistories.map((pointHistory) => (
            <PointCard pointHistory={pointHistory} />
          ))}
      </div>
    </>
  );
};
