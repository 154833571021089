import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { PhoneNumber } from '../../components/PhoneNumber';
import { TextField } from '../../components/TextField';
import { Toggle } from '../../components/Toggle';
import { TopBar } from '../../components/TopBar';
import { api } from '../../plugins/axios';
import { meState } from '../../plugins/ridge';

interface FormValues {
  name: string;
  phoneNumber: string;
  marketingTerms: boolean;
}

export const MyEditPage = () => {
  const me = meState.useValue();
  const [phoneNum, setPhoneNum] = useState<string>('');
  const [phoneValue, setPhoneValue] = useState<string>('');
  const [isVerified, setIsVerified] = useState<boolean>(false);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!me) return;
    setValue('name', me.name);
    setValue('phoneNumber', me.phoneNumber);
    setValue('marketingTerms', me.marketingTerms);
    setPhoneNum(me.phoneNumber);
  }, [setValue, me]);

  if (!me) {
    return <></>;
  }
  return (
    <>
      <TopBar text="내 정보 수정" backButton />
      <div className="p-6 space-y-4 flex-1 flex flex-col">
        <TextField
          label="이름*"
          placeholder="이름을 입력해주세요."
          helper={errors.name?.message}
          {...register('name', {
            required: '이름을 입력해주세요',
          })}
        />
        <TextField
          label="이메일*"
          placeholder="이메일 입력해주세요."
          value={me.email}
          disabled
        />
        <PhoneNumber
          label="휴대폰 인증"
          phoneNum={phoneNum}
          setPhoneNum={setPhoneNum}
          isVerified={isVerified}
          setIsVerified={setIsVerified}
          setPhoneValue={setPhoneValue}
          skip
        />
        <div className="flex space-x-3 items-center text-sm">
          <Toggle
            checked={me.marketingTerms}
            onChange={(checked) => setValue('marketingTerms', checked)}
          />
          <p>마케팅 약관 동의</p>
        </div>
        <div className="flex-1" />
        <Button
          text="내정보 수정"
          className="filled-brand-1 w-full"
          disabled={
            !watch('name') ||
            !watch('phoneNumber') ||
            (phoneValue.length > 0 && !isVerified)
          }
          onClick={handleSubmit(async (data) => {
            if (me?.phoneNumber !== phoneNum) {
              data.phoneNumber = phoneNum;
            }
            try {
              await api.patch('/users/me', data).then((res) => {
                meState.set(res.data);
                toast.success('저장되었습니다.');
              });
            } catch (e) {
              toast.error('내 정보 수정 오류');
            }
          })}
        />
      </div>
    </>
  );
};
