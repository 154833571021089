import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { api } from '../../plugins/axios';
import { CashReceiptUserType, PaymentOrder } from '../../types';
import { Button } from '../Button';
import { Radio } from '../Radio';
import { RadioGroup } from '../RadioGroup';
import { TextField } from '../TextField';
import { ModalAnimation } from './ModalAnimation';

interface FormValues {
  cashReceiptUserType: CashReceiptUserType;
  cashReceiptName: string;
  cashReceiptNumber: string;
}

interface CashReceiptModalProps {
  paymentOrder: PaymentOrder;
  open: boolean;
  onClose: () => void;
}

export const CashReceiptModal: FC<CashReceiptModalProps> = ({
  paymentOrder,
  open,
  onClose,
}) => {
  const [isCompany, setIsCompany] = useState<CashReceiptUserType>();

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  useEffect(() => {
    if (!paymentOrder.cashReceiptName) return;
    setValue('cashReceiptUserType', paymentOrder.cashReceiptUserType);
    setValue('cashReceiptName', paymentOrder.cashReceiptName);
    setValue('cashReceiptNumber', paymentOrder.cashReceiptNumber);
    setIsCompany(paymentOrder.cashReceiptUserType);
  }, [setValue, paymentOrder]);

  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title="현금영수증 요청"
        content={
          <div className="space-y-6">
            <div className="flex space-x-5 pt-2">
              <RadioGroup>
                <Radio
                  label="개인"
                  onClick={() => {
                    setIsCompany(CashReceiptUserType.INDIVIDUAL);
                    setValue(
                      'cashReceiptUserType',
                      CashReceiptUserType.INDIVIDUAL
                    );
                  }}
                  checked={isCompany === CashReceiptUserType.INDIVIDUAL}
                />
                <Radio
                  label="사업자"
                  onClick={() => {
                    setIsCompany(CashReceiptUserType.ENTREPRENEUR);
                    setValue(
                      'cashReceiptUserType',
                      CashReceiptUserType.ENTREPRENEUR
                    );
                  }}
                  checked={isCompany === CashReceiptUserType.ENTREPRENEUR}
                />
              </RadioGroup>
            </div>
            {isCompany !== CashReceiptUserType.ENTREPRENEUR ? (
              <>
                <TextField
                  label="이름"
                  placeholder="이름을 입력해주세요."
                  helper={errors.cashReceiptName?.message}
                  {...register('cashReceiptName', {
                    required: '이름을 입력해주세요..',
                  })}
                />
                <TextField
                  label="핸드폰 번호"
                  placeholder="핸드폰 번호를 입력해주세요."
                  helper={errors.cashReceiptNumber?.message}
                  {...register('cashReceiptNumber', {
                    required: '핸드폰 번호를 입력해주세요.',
                  })}
                />
              </>
            ) : (
              <>
                <TextField
                  label="회사명"
                  placeholder="회사명을 입력해주세요."
                  helper={errors.cashReceiptName?.message}
                  {...register('cashReceiptName', {
                    required: '회사명을 입력해주세요.',
                  })}
                />
                <TextField
                  label="사업자번호"
                  placeholder="사업자번호를 입력해주세요."
                  helper={errors.cashReceiptNumber?.message}
                  {...register('cashReceiptNumber', {
                    required: '사업자번호를 입력해주세요.',
                  })}
                />
              </>
            )}

            <Button
              type="button"
              className="filled-gray-900 w-full"
              text="저장하기"
              disabled={
                !watch('cashReceiptUserType') ||
                !watch('cashReceiptName') ||
                !watch('cashReceiptNumber')
              }
              onClick={handleSubmit(async (data) => {
                try {
                  await api.patch(
                    `/payment-orders/cash-receipt/${paymentOrder.id}`,
                    data
                  );
                  onClose();
                  toast.success('현금영수증 발행이 요청되었습니다.');
                } catch (e) {
                  toast.error('현금영수증 요청 오류');
                }
              })}
            />
          </div>
        }
      />
    </>
  );
};
