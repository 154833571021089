import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { ModalAnimation } from '../../../components/modal/ModalAnimation';
import { Select } from '../../../components/Select';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { adminApi } from '../../../plugins/axios';
import { PointHistoryType } from '../../../types';

interface FormValues {
  pointType: PointHistoryType;
  title: string;
  value: number;
  memo: string;
  userId: number;
}

interface PointAddModalProps {
  open: boolean;
  userId: number;
  onClose: () => void;
  refetch: () => void;
}

export const PointAddModal: FC<PointAddModalProps> = ({
  open,
  userId,
  onClose,
  refetch,
}) => {
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title="적립금 지급 / 차감하기"
        content={
          <div className="space-y-6">
            <Select
              value={watch('pointType')}
              onChange={(e) =>
                setValue('pointType', e.target.value as PointHistoryType)
              }
            >
              <option label="적립금 타입을 선택해주세요." disabled selected />
              <option value={PointHistoryType.PROVISION}>지급</option>
              <option value={PointHistoryType.DEDUCTIBLE}>차감</option>
            </Select>
            <TextField
              type="number"
              label="금액"
              helper={errors.value?.message}
              {...register('value', {
                required: '금액을 입력해주세요',
              })}
            />
            <TextField
              label="타이틀"
              helper={errors.title?.message}
              {...register('title', {
                required: '타이틀을 입력해주세요',
              })}
            />
            <TextArea className="h-32" label="메모" {...register('memo')} />
            <Button
              type="button"
              className="filled-brand-1 w-full"
              text="완료"
              disabled={
                !watch('pointType') || !watch('value') || !watch('title')
              }
              onClick={handleSubmit(async (data) => {
                data.userId = userId;
                try {
                  await adminApi.post(`admin/point-histories`, data);
                  await refetch();
                  await onClose();
                  toast.success('완료되었습니다.');
                } catch (e) {
                  toast.error('유저 적립금 지급/차감 오류');
                }
              })}
            />
          </div>
        }
      />
    </>
  );
};
