import React, { FC } from 'react';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Icon } from './Icon';

interface DateSelectFiledProps {
  checkIn: string | null;
  checkOut: string | null;
  onClick: () => void;
}

export const DateSelectFiled: FC<DateSelectFiledProps> = ({
  checkIn,
  checkOut,
  onClick,
}) => {
  return (
    <div
      className="border px-4 py-3 border-brand-1"
      onClick={() => {
        if (!checkIn || !checkOut) {
          onClick();
        }
      }}
    >
      <div className="flex space-x-3">
        <Icon.Calendar />
        {checkIn && checkOut ? (
          <div className="flex justify-between items-center w-full text-15">
            <div className="flex text-brand-1 space-x-1">
              <p>{`${utcToLocalFormat(
                checkIn,
                MomentFormat.YMD
              )} - ${utcToLocalFormat(checkOut, MomentFormat.YMD)}`}</p>
            </div>
            <div className="text-gray-600" onClick={() => onClick()}>
              <p>재검색</p>
            </div>
          </div>
        ) : (
          <p className="text-brand-1">날짜를 선택해주세요.</p>
        )}
      </div>
    </div>
  );
};
