import { useEffect, useState } from 'react';
import { TopNavBar } from '../../components/TopNavBar';
import { Description } from '../../../types';
import { useQuery } from 'react-query';
import { adminFetcher } from '../../../plugins/react-query';
import { EditModal } from '../../components/modal/editModal';
import { Button } from '../../../components/Button';
import { Editor } from '../../../plugins/froala';
import { adminApi } from '../../../plugins/axios';
import { useHistory } from 'react-router-dom';

export const ReservationPolicyEditor = () => {
  const { push } = useHistory();
  const pageTitle = 'POLICY';
  const { data: description, refetch } = useQuery<Description>(
    `admin/descriptions?title=${pageTitle}`,
    adminFetcher,
    { enabled: !!pageTitle }
  );

  const [id, setId] = useState<number>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [content, setContent] = useState(
    description?.content ? description?.content : description?.content
  );

  useEffect(() => {
    if (description) {
      setContent(description?.content);
      setId(description?.id);
    }
    if (description?.id == undefined) setContent('');
  }, [description]);
  async function save() {
    const data: any = {
      id,
      content,
      pageTitle,
    };
    !description?.id
      ? await adminApi
          .post(`/admin/descriptions`, data)
          .catch((e) => console.log(e))
      : await adminApi
          .patch(`/admin/descriptions/update/${description.id}`, data)
          .catch((e) => console.log(e));

    await refetch();
    setIsOpen(true);
    push(`/admin/reserve/policy/editor`);
  }

  return (
    <>
      <TopNavBar title="예약 취소 환불 정책 설정" />
      <div className="space-y-3 p-6 w-full">
        <Editor
          model={content}
          onModelChange={(model: any) => {
            setContent(model);
          }}
        />
      </div>
      <div className="flex-1" />
      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <EditModal
          open={isOpen}
          onClose={() => setIsOpen(false)}
          refetch={() => refetch()}
        />
        <Button
          text={description?.id ? '수정하기' : '생성하기'}
          className="filled-brand-1 w-full"
          onClick={save}
        />
      </div>
    </>
  );
};
