import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { ModalAnimation } from '../../../components/modal/ModalAnimation';
import { Select } from '../../../components/Select';
import { adminApi } from '../../../plugins/axios';
import { Reservation, ReservationState } from '../../../types';

interface ReservationStatusModalProps {
  reservation: Reservation;
  open: boolean;
  refetch: () => void;
  onClose: () => void;
}

interface FormValues {
  reservationState: ReservationState;
}

export const ReservationStatusModal: FC<ReservationStatusModalProps> = ({
  reservation,
  open,
  refetch,
  onClose,
}) => {
  const { watch, setValue, handleSubmit } = useForm<FormValues>();

  return (
    <>
      <ModalAnimation
        open={open}
        onClose={onClose}
        className="w-full p-6 max-w-md"
        title="예약상태 수동변경"
        content={
          <div className="space-y-6">
            <Select
              value={watch('reservationState')}
              onChange={(e) =>
                setValue('reservationState', e.target.value as ReservationState)
              }
            >
              <option value={''} selected disabled>
                예약 상태를 선택해주세요.
              </option>
              <option
                value={ReservationState.BEFORE_DEPOSIT}
                hidden={reservation.state === ReservationState.BEFORE_DEPOSIT}
              >
                입금전
              </option>
              <option
                value={ReservationState.APPLICATION_FOR_RESERVATION}
                hidden={
                  reservation.state ===
                  ReservationState.APPLICATION_FOR_RESERVATION
                }
              >
                예약신청
              </option>
              <option
                value={ReservationState.RESERVATION_CONFIRMATION}
                hidden={
                  reservation.state ===
                  ReservationState.RESERVATION_CONFIRMATION
                }
              >
                {' '}
                예약확정
              </option>
              <option
                value={ReservationState.CANCELLATION_REQUEST}
                hidden={
                  reservation.state === ReservationState.CANCELLATION_REQUEST
                }
              >
                취소신청
              </option>
              <option
                value={ReservationState.CANCELED}
                hidden={reservation.state === ReservationState.CANCELED}
              >
                취소완료
              </option>
              <option
                value={ReservationState.USED}
                hidden={reservation.state === ReservationState.USED}
              >
                이용완료
              </option>
            </Select>

            <Button
              type="button"
              className="filled-brand-1 w-full"
              text="변경하기"
              onClick={handleSubmit(async (data) => {
                try {
                  await adminApi.patch(
                    `/admin/reservations/state/${reservation.id}`,
                    data
                  );
                  refetch();
                  toast.success('예약 상태가 변경되었습니다.');
                } catch (e) {
                  toast.error('예약 상태 변경 오류');
                }
              })}
            />
          </div>
        }
      />
    </>
  );
};
