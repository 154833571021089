import React, { FC } from 'react';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { PointHistory, PointHistoryType } from '../types';

export interface PointCardProps {
  pointHistory: PointHistory;
}

export const PointCard: FC<PointCardProps> = ({ pointHistory }) => {
  return (
    <>
      <div className="flex justify-between items-center py-4 border-b border-gray-100">
        <div style={{ wordBreak: 'keep-all' }}>
          <div className="text-sm text-gray-500 font-light">
            {utcToLocalFormat(
              pointHistory.createdAt.toLocaleString(),
              MomentFormat.YYYYMMDDHmm
            )}
          </div>
          <div className="text-base text-gray-600">{pointHistory.title}</div>
        </div>
        <div className="text-lg font-semibold whitespace-pre">
          {pointHistory.pointType === PointHistoryType.PROVISION ? '+' : '-'}
          {pointHistory.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          원
        </div>
      </div>
    </>
  );
};
