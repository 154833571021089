import React from 'react';
import { TopBar } from '../../components/TopBar';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useQuery } from 'react-query';
import {
  Description,
  PageTitle,
  PaymentMethod,
  Reservation,
} from '../../types';
import { fetcher } from '../../plugins/react-query';
import { useParams } from 'react-router-dom';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import moment from 'moment';
import { haueRoomThumbnails, maruRoomDetailImages } from '../../dummies';
import { tabSelectPageTitleState } from '../../plugins/ridge';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';

export const MyCancelReservationDetail = () => {
  const { id } = useParams<{ id: string }>();
  tabSelectPageTitleState.set(PageTitle.POLICY);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );
  const { data: reservation } = useQuery<Reservation>(
    `/reservations/${id}`,
    fetcher,
    {
      enabled: !!id,
    }
  );

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    reservation?.checkIn &&
    reservation?.checkOut &&
    moment(reservation?.checkOut).diff(moment(reservation?.checkIn), 'days');

  // 옵션 상품 금액
  const roomOptionPrice = reservation?.paymentOrder?.orderRoomOptions?.map(
    (orderRoomOption) => {
      return Number(orderRoomOption.quantity) * orderRoomOption.price;
    }
  );

  // 선택한 옵션 총 금액
  const roomOptionTotalPrice = roomOptionPrice?.reduce((stack, el) => {
    return stack + el;
  }, 0);

  // 숙소 썸네일
  const maruRoomThumbnails = maruRoomDetailImages.filter(
    (image) => image.roomId === Number(id) || image.roomId === 0
  );
  const haueRoomDetailThumbnails = haueRoomThumbnails.filter(
    (image) => image.roomId === Number(id) || image.roomId === 0
  );
  const roomThumbnail =
    reservation?.roomTypeId === 1
      ? maruRoomThumbnails
      : haueRoomDetailThumbnails;

  if (!reservation) {
    return <></>;
  }
  return (
    <>
      <TopBar text="예약취소" backButton />
      <div>
        <h1 className="text-2xl px-6 py-4 font-medium">취소 확인하기</h1>
        <Swiper
          spaceBetween={0}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {roomThumbnail.map((img, i) => (
            <SwiperSlide className="aspect-[5/3] relative" key={i}>
              <img
                src={img.image}
                alt=""
                className="w-full h-full object-cover absolute"
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="p-6">
          <div className="px-1">
            <FroalaEditorView model={description?.content} />
          </div>
        </div>

        <div className="h-3 bg-gray-50" />

        <div className="p-6">
          <h3 className="text-xl font-semibold pb-3 border-b mb-3">
            예약 숙소 정보
          </h3>
          <div className="space-y-2">
            <div className="flex justify-between items-center text-lg font-medium">
              <div className="">숙소명</div>
              <div className="">{reservation.roomTypeTitle}</div>
            </div>
            {[
              {
                label: '객실명',
                content: reservation.roomTitle,
              },
              {
                label: '예약 기간',
                content: `${utcToLocalFormat(
                  reservation.checkIn,
                  MomentFormat.YMDdd
                )} - ${utcToLocalFormat(
                  reservation.checkOut,
                  MomentFormat.YMDdd
                )} ${countingNights}박${Number(countingNights) + 1}일`,
              },
              {
                label: '예약자명',
                content: reservation.reservationName,
              },
              {
                label: '예약자 핸드폰 번호',
                content: reservation.reservationPhoneNumber,
              },
              {
                label: '인원수',
                content: `성인 ${reservation.adult}명 / 아동 ${
                  reservation.child ? reservation.child : 0
                }명 / 영아 ${reservation.infant ? reservation.infant : 0}명`,
              },
              {
                label: '추가사항',
                content: reservation.paymentOrder.orderRoomOptions.map(
                  (orderRoomOption) => (
                    <>
                      {orderRoomOption.title}({orderRoomOption.quantity}){' '}
                    </>
                  )
                ),
              },
              {
                label: '요청사항',
                content: reservation.requestedTerm || '-',
              },
            ].map((e, i) => (
              <div
                className="flex justify-between items-center text-sm"
                key={i}
              >
                <div className="text-gray-600 whitespace-pre">{e.label}</div>
                <div
                  className="text-gray-900 text-13 text-right"
                  style={{ wordBreak: 'keep-all' }}
                >
                  {e.content}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="h-3 bg-gray-50" />

        <div className="p-6">
          <h3 className="text-xl font-semibold pb-3 border-b mb-3">
            취소 정보
          </h3>
          <div className="space-y-2">
            <div className="flex justify-between items-center text-lg font-medium">
              <div className="text-gray-600 font-normal text-sm">
                총 환불 금액
              </div>
              <div className="">
                {(reservation.paymentOrder.cancelledPrice || 0)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
              </div>
            </div>
            {[
              {
                label: '취소 신청 일시',
                content: reservation.cancelRequestedAt
                  ? utcToLocalFormat(
                      reservation.cancelRequestedAt,
                      MomentFormat.YYYYMMDDHmmss
                    )
                  : '-',
              },
              {
                label: '취소일시',
                content: reservation.canceledAt
                  ? `${utcToLocalFormat(
                      reservation.canceledAt,
                      MomentFormat.YYYYMMDDHmmss
                    )} `
                  : '-',
              },
              {
                label: '반환 적립금',
                content:
                  (reservation.paymentOrder.returnedPoint || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
              },
            ].map((e, i) => (
              <div
                className="flex justify-between items-center text-sm"
                key={i}
              >
                <div className="text-gray-600">{e.label}</div>
                <div className="text-gray-900">{e.content}</div>
              </div>
            ))}

            {}
            <div className="flex justify-between text-sm text-right">
              <div className="text-gray-600">환불 처리 수단</div>
              <div className="text-gray-900 space-y-1">
                {reservation.paymentOrder.paymentMethod ===
                PaymentMethod.BANK ? (
                  <>
                    <p>계좌 환불</p>
                    <p>
                      {reservation.paymentOrder.refundBank}{' '}
                      {reservation.paymentOrder.refundAccount}{' '}
                      {reservation.paymentOrder.depositor}
                    </p>
                  </>
                ) : (
                  <p>
                    {reservation.paymentOrder.paymentMethod ===
                    PaymentMethod.CARD
                      ? '카드결제 취소'
                      : reservation.paymentOrder.paymentMethod ===
                        PaymentMethod.K_PAY
                      ? '카카오 페이 결제 취소'
                      : reservation.paymentOrder.paymentMethod ===
                        PaymentMethod.N_PAY
                      ? '네이버 페이 결제 취소'
                      : '-'}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="my-5 border-t border-gray-100" />

          <div className="space-y-2">
            <div className="flex justify-between items-center text-lg font-medium">
              <div className="text-gray-600 font-normal text-sm">
                총 결제 금액
              </div>
              <div className="">
                {reservation.paymentOrder.finalPrice
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원'}
              </div>
            </div>
            {[
              {
                label: '객실 요금',
                content:
                  (reservation.paymentOrder.roomPrice
                    ? reservation.paymentOrder.roomPrice
                    : reservation.paymentOrder.totalPrice
                  )
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
              },
              {
                label: '추가 인원 요금',
                content:
                  (reservation.paymentOrder.additionalPersonAmount || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
              },
              {
                label: '추가 옵션',
                content:
                  (roomOptionTotalPrice || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원',
              },
              {
                label: '적립금 사용 할인금액',
                content:
                  (reservation.usedPoint || 0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') + 'P',
              },
            ].map((e, i) => (
              <div
                className="flex justify-between items-center text-sm"
                key={i}
              >
                <div className="text-gray-600">{e.label}</div>
                <div className="text-gray-900">{e.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
