import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { TopBar } from '../../components/TopBar';
import { useAuth } from '../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

interface FormValues {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const { login } = useAuth();
  const { push } = useHistory();

  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('이메일을 입력해주세요')
      .email('이메일 형식이 아닙니다.'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(formSchema),
  });

  return (
    <>
      <TopBar text="Login" backButton />
      <h1 className="pl-6 text-brand-1 mt-12">LOGIN</h1>

      <form
        className="p-6 flex flex-col h-full space-y-4"
        onSubmit={handleSubmit((data) => login(data))}
      >
        <TextField
          label="이메일"
          type="email"
          placeholder="이메일을 입력해주세요."
          helper={errors.email?.message}
          {...register('email', { required: '이메일을 입력해주세요' })}
        />

        <TextField
          label="비밀번호"
          type="password"
          placeholder="영문 숫자 포함 8자리를 입력해주세요."
          helper={errors.password?.message}
          {...register('password', {
            required: '비밀번호를 입력해주세요',
            minLength: { value: 8, message: '최소 8자 이상 가능합니다' },
            maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
          })}
        />

        <div className="self-end pb-16 flex-1">
          <button
            type="button"
            onClick={() => push('find/email')}
            className="text-14 pr-3 text-gray-700"
          >
            이메일 찾기
          </button>
          |
          <button
            type="button"
            onClick={() => push('find/password')}
            className="text-14 pl-3 text-gray-700"
          >
            비밀번호 찾기
          </button>
        </div>
        <Button text="로그인" className="filled-brand-1" />
      </form>
      <Button
        text="회원가입"
        className="text-brand-1 mb-4"
        onClick={() => push('/signup')}
      />
    </>
  );
};
