import React from 'react';
import { TopBar } from '../../components/TopBar';
import 'swiper/css';
import 'swiper/css/pagination';
import { Button } from '../../components/Button';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';
import moment from 'moment';
import { useQuery } from 'react-query';
import { Description, PageTitle, Room } from '../../types';
import { fetcher } from '../../plugins/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { tabSelectPageTitleState } from '../../plugins/ridge';

export const RoomDetailPage = () => {
  tabSelectPageTitleState.set(PageTitle.POLICY);
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description } = useQuery<Description>(
    `/descriptions?title=${pageTitle}`,
    fetcher
  );
  const { push } = useHistory();
  const { id } = useParams<{ id: string }>();
  const checkIn = localStorage.getItem('checkIn');
  const checkOut = localStorage.getItem('checkOut');

  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    checkIn && checkOut && moment(checkOut).diff(moment(checkIn), 'days');

  const { data: todayRoom } = useQuery<Room>(`/rooms/${id}`, fetcher, {
    enabled: !!id,
  });

  const { data: room } = useQuery<Room>(
    `/rooms/selectDate/${id}?checkIn=${moment(
      checkIn
    ).toISOString()}&checkOut=${moment(checkOut).toISOString()}`,
    fetcher,
    {
      enabled: !!checkIn && !!checkOut && !!id,
    }
  );

  const { data: roomTotalPrice } = useQuery<number>(
    `/rooms/totalPrice/${id}?checkIn=${moment(
      checkIn
    ).toISOString()}&checkOut=${moment(checkOut).toISOString()}`,
    fetcher,
    {
      enabled: !!checkIn && !!checkOut && !!id,
    }
  );

  // 타임슬롯 요일 구하기
  const findDay = moment().weekday();

  // 요일별 금액 보여주기
  const totalPrice = todayRoom?.timeslots[0].detailPrice
    ? todayRoom?.timeslots[0].detailPrice
    : findDay === 0
    ? todayRoom?.roomType.sundayPrice
    : findDay === 5
    ? todayRoom?.roomType.fridayPrice
    : findDay === 6
    ? todayRoom?.roomType.saturdayPrice
    : todayRoom?.roomType.weekdayPrice;

  if (!room) {
    return <Loading />;
  }
  return (
    <>
      <TopBar
        text={checkIn && checkOut ? 'RESERVATION' : 'ATIN MARU'}
        backButton
      />

      <div className="px-6 py-6 border-b border-gray-100">
        <div className="space-y-1">
          <p className="text-15 text-gray-600 font-medium">선택한 날짜</p>
          <p className="text-20 text-brand-1 font-semibold">
            {checkIn && checkOut
              ? `${utcToLocalFormat(
                  checkIn,
                  MomentFormat.YMD
                )} - ${utcToLocalFormat(checkOut, MomentFormat.YMD)}`
              : '날짜 선택 후 예약이 가능합니다.'}
          </p>
        </div>
      </div>

      <div className="p-6">
        <p className="text-gray-400 text-sm">
          {room?.roomType?.id === 1 ? 'CABINS' : 'HAEU'}
        </p>
        <h3 className="text-2xl font-medium">{room?.title}</h3>

        <p className="text-17 text-gray-800 font-medium">
          {checkIn && checkOut && room && countingNights ? (
            <>
              {roomTotalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              {`₩ / ${countingNights}박`}
            </>
          ) : (
            <>
              {(totalPrice || 0)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              {'₩ / 1박'}
            </>
          )}
        </p>
      </div>

      <div className="px-1">
        <FroalaEditorView model={room?.accommodationInfo} />
      </div>

      <div className="h-3 bg-gray-50" />

      <div className="px-1">
        <FroalaEditorView model={description?.content} />
      </div>

      <div
        className="px-6 mb-10 mt-4 text-15 text-brand-1 underline"
        onClick={() => {
          room?.roomType?.id === 1 ? push('/home/maru') : push('/home/haeu');
        }}
      >
        <span>안내 사항 자세히 보기 {'>'}</span>
      </div>

      {checkIn &&
        checkOut &&
        room &&
        room?.timeslots.length > 0 &&
        countingNights === room.timeslots.length && (
          <div className="pt-16">
            <Button
              type="button"
              onClick={() => {
                if (room?.roomType?.moreThanTwoNights && countingNights < 2) {
                  alert('2박 이상 선택 후 예약결제 진행해주세요.');
                } else {
                  push(`/order/${id}`);
                }
              }}
              text="결제하러가기"
              className="filled-brand-1 h-16 fixed bottom-0 w-full max-w-md"
            />
          </div>
        )}
    </>
  );
};
