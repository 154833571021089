import { useEffect, useState } from 'react';
import { TopNavBar } from '../../components/TopNavBar';
import { Button } from '../../../components/Button';
import { adminApi } from '../../../plugins/axios';
import { useHistory } from 'react-router-dom';
import { PageTitleTab } from '../../components/PageTitleTab';
import { tabSelectPageTitleState } from '../../../plugins/ridge';
import { useQuery } from 'react-query';
import { adminFetcher } from '../../../plugins/react-query';
import { EditModal } from '../../components/modal/editModal';
import { Description, PageTitle } from '../../../types';
import { Editor } from '../../../plugins/froala';
import { Disclosure } from '@headlessui/react';
import ChevronUpIcon from '@heroicons/react/outline/ChevronUpIcon';
import { v4 as uuidv4 } from 'uuid';
import { ReactComponent as FileUploadImg } from '../../../assets/svg/file-upload.svg';
import { useForm } from 'react-hook-form';

interface FormValues {
  title: PageTitle;
  file: File;
}

export const HomePageEditor = () => {
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const { push } = useHistory();
  const pageTitle = tabSelectPageTitleState.useValue();
  const { data: description, refetch } = useQuery<Description>(
    `admin/descriptions?title=${pageTitle}`,
    adminFetcher,
    { enabled: !!pageTitle }
  );

  const [content, setContent] = useState(description?.content);
  const [id, setId] = useState<number>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [productPhoto, setProductPhoto] = useState<File>();
  const [defaultProductPhoto, setDefaultProductPhoto] = useState<any>(
    description?.imageFile
  );
  const key = uuidv4();

  useEffect(() => {
    if (description) {
      setContent(description?.content);
      setId(description?.id);
      setDefaultProductPhoto(description?.imageFile);
    }
    setDefaultProductPhoto(description?.imageFile);
    if (description?.id == undefined) setContent('');
  }, [description]);

  async function thumbnailSave(data: FormValues) {
    data.title = pageTitle;
    if (productPhoto) data.file = productPhoto;

    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) =>
      formData.append(name, value)
    );

    description?.imageFile
      ? await adminApi
          .patch(`/admin/descriptions/upload/${description.id}`, formData)
          .catch((e) => console.log(e))
      : await adminApi
          .post(`/admin/descriptions/upload`, formData)
          .catch((e) => console.log(e));

    await refetch();
    setIsOpen(true);
    push(`/admin/description/editor`);
  }

  async function save() {
    const data: any = {
      content,
      pageTitle,
      id,
    };
    !description?.id
      ? await adminApi
          .post(`/admin/descriptions`, data)
          .catch((e) => console.log(e))
      : await adminApi
          .patch(`/admin/descriptions/update/${description.id}`, data)
          .catch((e) => console.log(e));
    await refetch();
    setIsOpen(true);
    push(`/admin/description/editor`);
  }

  return (
    <>
      <TopNavBar title="메인 페이지 관리 설정" />
      <PageTitleTab pageTitle={pageTitle} refetch={() => refetch()} />
      <div className="space-y-3 p-6 w-full">
        <Disclosure as="div">
          {({ open }) => (
            <Disclosure>
              <Disclosure.Button className="focus:outline-none flex w-full justify-between py-2 font-medium border-b">
                <span> 썸네일 수정 </span>
                <ChevronUpIcon
                  className={`${open ? '' : 'rotate-180 transform'} h-5 w-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 space-y-2">
                <>
                  <div className="p-6 space-y-4 flex-1 flex flex-col">
                    <label htmlFor={key}>
                      <div className="relative pb-full rounded-md border-2 border-grey-5">
                        {defaultProductPhoto || productPhoto ? (
                          <>
                            <img
                              className="relative pb-full"
                              src={
                                productPhoto
                                  ? URL.createObjectURL(productPhoto)
                                  : defaultProductPhoto
                              }
                              alt=""
                            />
                            <div className="absolute px-3 py-1.5 flex bg-brand-1 text-white rounded-lg top-2 left-2">
                              사진 수정하기
                            </div>
                          </>
                        ) : (
                          <div className=" md:pr-1 md:py-6">
                            <div className="flex flex-col justify-center items-center space-y-2 w-full h-full">
                              <FileUploadImg />
                              <div className="text-sm text-gray-500 text-center pt-1">
                                이미지(jpg, png) 파일을 업로드해주세요.
                                <br />
                                (최대 1장)
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </label>
                    <input
                      type="file"
                      id={key}
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => {
                        if (!e.target.validity.valid) return;
                        const file = e.target.files?.item(0);
                        if (file) {
                          setProductPhoto(file);
                        }
                      }}
                    />

                    <div className="flex-1" />
                    <div className="space-y-3">
                      <EditModal
                        open={isOpen}
                        onClose={() => setIsOpen(false)}
                        refetch={() => ''}
                      />
                      <Button
                        text={
                          description?.imageFile
                            ? '이미지 수정하기'
                            : '이미지 업로드'
                        }
                        disabled={!productPhoto}
                        className="filled-brand-1 w-full"
                        onClick={handleSubmit((data) => {
                          thumbnailSave(data);
                        })}
                      />
                    </div>
                  </div>
                </>
              </Disclosure.Panel>
            </Disclosure>
          )}
        </Disclosure>
      </div>
      <div className=" p-6 w-full">
        <Disclosure as="div">
          {({ open }) => (
            <Disclosure>
              <Disclosure.Button className="focus:outline-none flex w-full justify-between py-2 font-medium border-b">
                <span>본문 내용 수정 </span>
                <ChevronUpIcon
                  className={`${open ? '' : 'rotate-180 transform'} h-5 w-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="pt-4 pb-2 space-y-2">
                <div className=" w-full">
                  <Editor
                    model={content}
                    onModelChange={(model: any) => {
                      setContent(model);
                    }}
                  />
                </div>

                <div className="flex-1" />

                <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
                  <EditModal
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    refetch={() => refetch()}
                  />
                  <Button
                    text={!description?.content ? '생성하기' : '수정하기'}
                    className="filled-brand-1 w-full"
                    onClick={save}
                  />
                </div>
              </Disclosure.Panel>
            </Disclosure>
          )}
        </Disclosure>
      </div>

      <div className="flex-1" />
    </>
  );
};
