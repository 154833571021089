import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';

export const SignupSuccessPage = () => {
  const { push } = useHistory();

  return (
    <>
      <div className="pt-36 px-6 space-y-6">
        <div className="flex justify-center text-brand-1 text-24 text-center font-semibold">
          회원가입이 완료되었습니다. <br /> 첫 여행을 예약해보세요.
        </div>
        <p className="text-center text-brand-1">
          첫 회원가입 10,000원 적립이 완료되었습니다.
          <br /> 숙소를 예약할때 바로 사용해보세요!
        </p>
      </div>
      <div className="flex-1" />
      <div className="p-8">
        <Button
          type="button"
          onClick={() => push('/home')}
          text="ATIN 둘러보기"
          className="filled-brand-1 w-full"
        />
      </div>
    </>
  );
};
