import React, { useState } from 'react';
import { TopBar } from '../../components/TopBar';
import { Tab } from '../../components/Tab';
import { ReservationItemCard } from '../../components/ReservationItemCard';
import { fetcher } from '../../plugins/react-query';
import { Reservation, ReservationState } from '../../types';
import { useQuery } from 'react-query';
import { meState } from '../../plugins/ridge';

export const MyReservation = () => {
  const me = meState.useValue();
  const [tab, setTab] = useState<number>(0);

  const { data: reservations } = useQuery<Reservation[]>(
    `/reservations/my`,
    fetcher,
    {
      enabled: !!me,
    }
  );

  // 예약 완료 리스트 ( 예약신청, 예약완료, 입금 전 )
  const reservationConfirmation = reservations?.filter(
    (reservation) =>
      reservation.state === ReservationState.APPLICATION_FOR_RESERVATION ||
      reservation.state === ReservationState.RESERVATION_CONFIRMATION ||
      reservation.state === ReservationState.BEFORE_DEPOSIT
  );

  // 이용 완료 리스트 ( 이용완료 )
  const reservationUsed = reservations?.filter(
    (reservation) => reservation.state === ReservationState.USED
  );

  // 예약 취소 신청 리스트
  const reservationCancellationRequest = reservations?.filter(
    (reservation) => reservation.state === ReservationState.CANCELLATION_REQUEST
  );

  // 예약 취소 완료 리스트 ( 예약취소신청 및 예약취소 )
  const reservationCanceled = reservations?.filter(
    (reservation) => reservation.state === ReservationState.CANCELED
  );

  return (
    <>
      <TopBar text="RESERVATION LIST" backButton />
      <div className="grid grid-cols-2">
        <Tab text="예약 내역" selected={tab === 0} onClick={() => setTab(0)} />
        <Tab
          text="예약 취소내역"
          selected={tab === 1}
          onClick={() => setTab(1)}
        />
      </div>

      {tab === 0 && (
        <>
          <div className="p-6">
            <h3 className="text-lg font-semibold">예약신청 내역</h3>
            {reservationConfirmation && reservationConfirmation?.length > 0 ? (
              reservationConfirmation?.map((reservation) => (
                <ReservationItemCard reservation={reservation} />
              ))
            ) : (
              <div className="text-center my-6 text-gray-500 text-15">
                예약완료된 숙소가 없습니다.
              </div>
            )}
          </div>

          <div className="h-3 bg-gray-50" />

          <div className="p-6">
            <h3 className="text-lg font-semibold">이용완료</h3>
            {reservationUsed && reservationUsed?.length > 0 ? (
              reservationUsed?.map((reservation) => (
                <ReservationItemCard reservation={reservation} />
              ))
            ) : (
              <div className="text-center my-6 text-gray-500 text-15">
                이용완료한 숙소가 없습니다.
              </div>
            )}
          </div>
        </>
      )}

      {tab === 1 && (
        <>
          <div className="p-6">
            <h3 className="text-lg font-semibold">예약취소 완료</h3>
            {reservationCanceled && reservationCanceled?.length > 0 ? (
              reservationCanceled?.map((reservation) => (
                <ReservationItemCard reservation={reservation} />
              ))
            ) : (
              <div className="text-center my-6 text-gray-500 text-15">
                예약취소 완료된 숙소가 없습니다.
              </div>
            )}
          </div>

          <div className="h-3 bg-gray-50" />

          <div className="p-6">
            <h3 className="text-lg font-semibold">예약취소 신청</h3>
            {reservationCancellationRequest &&
            reservationCancellationRequest?.length > 0 ? (
              reservationCancellationRequest?.map((reservation) => (
                <ReservationItemCard reservation={reservation} />
              ))
            ) : (
              <div className="text-center my-6 text-gray-500 text-15">
                예약취소 신청된 숙소가 없습니다.
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
