import React from 'react';
import { Link } from 'react-router-dom';
import { haueRoomThumbnails, maruRoomThumbnails } from '../dummies';
import { Room } from '../types';
import moment from 'moment';

interface RoomCardProps {
  price?: number;
  maxTimeslotLength?: number;
  checkIn?: string;
  checkOut?: string;
  room: Room;
}

export const RoomCard: React.FC<RoomCardProps> = ({
  price,
  maxTimeslotLength,
  checkIn,
  checkOut,
  room,
}) => {
  // 사용자가 선택한 날짜 연박일 수 계산
  const countingNights =
    checkIn && checkOut && moment(checkOut).diff(moment(checkIn), 'days');

  const roomThumbnail =
    room.roomType.id === 1
      ? maruRoomThumbnails.find((thumbnail) => thumbnail.roomId === room.id)
      : haueRoomThumbnails.find((thumbnail) => thumbnail.roomId === room.id);

  return (
    <Link
      className={`mb-2 ${
        ((room.timeslots && room.timeslots.length === 0) ||
          ((maxTimeslotLength !== room.timeslots.length ||
            countingNights !== room.timeslots.length) &&
            checkIn &&
            checkOut)) &&
        'opacity-20'
      }`}
      to={`/reservation/room/${room.id}`}
    >
      <div className={'relative aspect-[5/4]'}>
        <img
          src={roomThumbnail?.image}
          alt=""
          className="w-full h-full object-cover absolute"
        />
      </div>
      <div className="mt-2">
        <h4 className="text-17 font-semibold">{room.title}</h4>
        <div className="mt-1">
          <p className="text-15">
            {price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ₩
          </p>
          <p className="text-14 text-gray-600 font-light">
            {room?.roomType?.id === 1
              ? '  기본 인원 1명/ 최대 2명'
              : '기본 인원 2명/ 최대 4명'}
          </p>
        </div>
      </div>
    </Link>
  );
};
