import React from 'react';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';

export const LoginPage = () => {
  return (
    <div className="p-6 space-y-5">
      <div className="space-y-2 py-8">
        <Logo className="mx-auto" />
        <p className="text-center">ATIN MARU ADMIN</p>
      </div>
      <TextField label="아이디" placeholder="이메일을 입력해주세요." />
      <TextField label="비밀번호" placeholder="비밀번호를 입력해주세요." />
      <Button text="로그인" className="filled-black w-full" />
    </div>
  );
};
