import React, { FC, useState } from 'react';
import { RoomOption } from '../types';
import { Icon } from './Icon';

interface RoomOptionItemProps {
  value?: number;
  roomOption: RoomOption;
  onChange?: (value: { id: number; count: number }) => void;
}

export const RoomOptionItem: FC<RoomOptionItemProps> = ({
  value = 0,
  roomOption,
  onChange,
}) => {
  const [count, setCount] = useState(value);

  return (
    <div className="space-y-1.5">
      <div className="flex items-center justify-between text-gray-800 text-14 font-bold">
        <span>{roomOption?.title}</span>
        <span>
          {roomOption.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
        </span>
      </div>

      <div className="flex justify-end">
        {count > 0 && (
          <Icon.Decrease
            className="cursor-pointer"
            onClick={() => {
              const newCount = count - 1;
              setCount(newCount);
              onChange?.({ id: roomOption.id, count: newCount });
            }}
          />
        )}
        <span className="mx-2.5 w-5 text-md text-center">
          {count <= 0 ? 0 : count}
        </span>
        {count < 20 && (
          <Icon.Increase
            className="cursor-pointer"
            onClick={() => {
              const newCount = count + 1;
              setCount(newCount);
              onChange?.({ id: roomOption.id, count: newCount });
            }}
          />
        )}
      </div>

      <p className="whitespace-pre-line text-14 text-gray-800">
        {roomOption?.description}
      </p>
    </div>
  );
};
